import { z } from "zod";
import {
  EContractorPaymentStatus,
  EContractorPaymentType,
} from "../contractor-payments";
import { ETxnSpeed } from "../misc-taxation";
import { schemes } from "../schema-validation";
import { EDisbursementMethod } from "../workers-shared";

const getMyContractorPaymentsQuerySchema = z.object({
  mode: z.enum(["test", "live"]),
});

export type TGetMyContractorPaymentsQuery = z.infer<
  typeof getMyContractorPaymentsQuerySchema
>;

const getMyContractorPaymentsResponseData = z.object({
  amount: z.number(),
  approval_required: z.boolean(),
  approved: z.boolean(),
  companyID: schemes.companyID(),
  contractorID: schemes.mongoObjectId(),
  disbursement: z
    .object({
      method: z.enum([
        EDisbursementMethod.direct_deposit,
        EDisbursementMethod.prepaid,
      ] as const),
    })
    .optional(),
  createdAt: z.date(),
  first_name: z.string(),
  last_name: z.string(),
  status: z.enum([
    EContractorPaymentStatus.pending,
    EContractorPaymentStatus["pre-processed"],
    EContractorPaymentStatus.processed,
  ]),
  pay_date: schemes.isoDate(),
  speed: z.nativeEnum(ETxnSpeed),
  type: z.enum([
    EContractorPaymentType.REGULAR,
    EContractorPaymentType.REIMBURSEMENT,
  ] as const),
});

export type TGetMyContractorPaymentsResponseData = Array<
  z.infer<typeof getMyContractorPaymentsResponseData>
>;
