import { EDocumentKey } from "./EDocumentKey";

type TextConfig = {
  text?: string;
  weight?: number;
  list?: string[];
  break?: boolean;
  link?: string;
  space?: string | number;
};

export const i9IDDocumentTextConfig: Partial<
  Record<EDocumentKey, TextConfig[]>
> = {
  [EDocumentKey.US_PASSPORT]: [
    { text: "U.S. Passport", weight: 700 },
    { text: " or ", weight: 400 },
    { text: "U.S. Passport Card", weight: 700 },
  ],
  [EDocumentKey.FORM_I551]: [
    { text: "Permanent Resident Card", weight: 700 },
    { text: " or ", weight: 400 },
    { text: "Alien Registration Receipt Card (Form I-551)", weight: 700 },
  ],
  [EDocumentKey.FOREIGN_PASSPORT_WITH_I551_STAMP]: [
    { text: "Foreign passport", weight: 700 },
    { text: " that contains a temporary", weight: 400 },
    { text: " I-551", weight: 700 },
    { text: " stamp or temporary", weight: 400 },
    { text: " I-551", weight: 700 },
    {
      text: " printed notation on a machine-readable immigrant visa",
      weight: 400,
    },
  ],
  [EDocumentKey.FORM_I766]: [
    { text: "Employment Authorization Document", weight: 700 },
    { text: " that contains a photograph", weight: 400 },
    { text: " (Form I-766)", weight: 700 },
  ],
  [EDocumentKey.FOREIGN_PASSPORT_WITH_FORM_I94]: [
    {
      text: "For an individual temporarily authorized to work for a specific employer because of his or her status or parole:",
      weight: 400,
    },
    { break: true, space: 1 },
    { text: "a.", weight: 400 },
    { text: " Foreign passport;", weight: 700 },
    { text: " and", weight: 400 },
    { break: true, space: 1 },
    { text: "b. Form", weight: 400 },
    { text: " I-94", weight: 700 },
    { text: " or Form", weight: 400 },
    { text: " I-94A", weight: 700 },
    { text: " that has the following:", weight: 400 },
    {
      list: [
        "The same name as the passport; and",
        "An endorsement of the individual's status or parole as long as that period of endorsement has not yet expired and the proposed employment is not in conflict with any restrictions or limitations identified on the form.",
      ],
    },
  ],
  [EDocumentKey.FORM_I94]: [
    { text: "Passport", weight: 700 },
    {
      text: " from the Federated States of Micronesia (FSM) or the Republic of the Marshall Islands (RMI) with",
      weight: 400,
    },
    { text: " Form I-94", weight: 700 },
    { text: " or", weight: 400 },
    { text: " Form I-94A", weight: 700 },
    {
      text: " indicating nonimmigrant admission under the Compact of Free Association Between the United States and the FSM or RMI",
      weight: 400,
    },
  ],
  [EDocumentKey.DRIVERS_LICENSE]: [
    { text: "Driver's license", weight: 700 },
    { text: " or", weight: 400 },
    { text: " ID card", weight: 700 },
    {
      text: " issued by a State or outlying possession of the United States provided it contains a photograph or information such as name, date of birth, gender, height, eye color, and address",
      weight: 400,
    },
  ],
  [EDocumentKey.GOVERNMENT_ID_CARD]: [
    { text: "ID card", weight: 700 },
    {
      text: " issued by federal, state or local government agencies or entities, provided it contains a photograph or information such as name, date of birth, gender, height, eye color, and address",
      weight: 400,
    },
  ],
  [EDocumentKey.SCHOOL_ID_CARD]: [
    { text: "School ID card", weight: 700 },
    { text: " with a photograph", weight: 400 },
  ],
  [EDocumentKey.VOTER_REGISTRATION_CARD]: [
    { text: "Voter's registration card", weight: 700 },
  ],
  [EDocumentKey.US_MILITARY_CARD]: [
    { text: "U.S. Military card", weight: 700 },
    { text: " or", weight: 400 },
    { text: " draft record", weight: 700 },
  ],
  [EDocumentKey.MILITARY_DEPENDENT_ID_CARD]: [
    { text: "Military dependent's ID card", weight: 700 },
  ],
  [EDocumentKey.US_COAST_GUARD_CARD]: [
    { text: "U.S. Coast Guard Merchant Mariner Card", weight: 700 },
  ],
  [EDocumentKey.NATIVE_AMERICAN_TRIBAL_DOCUMENT]: [
    { text: "Native American tribal document", weight: 700 },
  ],
  [EDocumentKey.CANADIAN_DRIVERS_LICENSE]: [
    { text: "Driver's license", weight: 700 },
    { text: " issued by a Canadian government authority", weight: 400 },
  ],
  [EDocumentKey.SCHOOL_RECORD]: [
    { text: "School record", weight: 700 },
    { text: " or", weight: 400 },
    { text: " report card", weight: 700 },
  ],
  [EDocumentKey.HOSPITAL_RECORD]: [
    { text: "Clinic, doctor,", weight: 700 },
    { text: " or", weight: 400 },
    { text: " hospital record", weight: 700 },
  ],
  [EDocumentKey.DAY_CARE_RECORD]: [
    { text: "Day-care", weight: 700 },
    { text: " or ", weight: 400 },
    { text: "nursery school record", weight: 700 },
  ],
  [EDocumentKey.SOCIAL_SECURITY_CARD]: [
    { text: "A", weight: 400 },
    { text: " Social Security Account Number card", weight: 700 },
    {
      text: ", unless the card includes one of the following restrictions:",
      weight: 400,
    },
    {
      list: [
        "Not valid for employment",
        "Valid for work only with INS authorization",
        "Valid for work only with DHS authorization",
      ],
    },
  ],
  [EDocumentKey.DS_1350]: [
    { text: "Form DS-1350 - Certification of report of birth", weight: 700 },
    { text: " issued by the Department of State", weight: 400 },
  ],
  [EDocumentKey.FS_545]: [
    { text: "Form FS-545 - Certification of report of birth", weight: 700 },
    { text: " issued by the Department of State", weight: 400 },
  ],
  [EDocumentKey.FS_240]: [
    { text: "Form FS-240 - Certification of report of birth", weight: 700 },
    { text: " issued by the Department of State", weight: 400 },
  ],
  [EDocumentKey.US_BIRTH_CERTIFICATE]: [
    { text: "Original", weight: 700 },
    { text: " or", weight: 400 },
    { text: " certified copy of birth certificate", weight: 700 },
    {
      text: " issued by a State, county, municipal authority, or territory of the United States bearing an official seal",
      weight: 400,
    },
  ],
  [EDocumentKey.FORM_I197]: [
    { text: "U.S. Citizen ID Card (", weight: 400 },
    { text: "Form I-197", weight: 700 },
    { text: ")", weight: 400 },
  ],
  [EDocumentKey.FORM_I179]: [
    { text: "Identification Card", weight: 700 },
    {
      text: " for Use of Resident Citizen in the United States (",
      weight: 400,
    },
    { text: "Form I-179", weight: 700 },
    { text: ")", weight: 400 },
  ],
  [EDocumentKey.EMPLOYMENT_AUTHORIZATION_DOCUMENT]: [
    { text: "Employment authorization document", weight: 700 },
    { text: " issued by the Department of Homeland Security", weight: 400 },
    { break: true, space: 1 },
    { text: " For examples, see ", weight: 400 },
    {
      text: "Section 7",
      weight: 400,
      link: "https://www.uscis.gov/i-9-central/handbook-for-employers-m-274/60-evidence-of-status-for-certain-categories",
    },
    { text: " and ", weight: 400 },
    {
      text: "Section 13",
      weight: 400,
      link: "https://www.uscis.gov/i-9-central/form-i-9-resources/handbook-for-employers-m-274/120-acceptable-documents-for-verifying-employment-authorization-and-identity/123-list-c-documents-that-establish-employment-authorization",
    },
    { text: " of the M-274 on ", weight: 400 },
    {
      text: "uscis.gov/i-9-central",
      weight: 400,
      link: "https://uscis.gov/i-9-central",
    },
    { text: ".", weight: 400 },
    { break: true, space: 1 },
    {
      text: "The Form I-766, Employment Authorization Document, is a List A, ",
      weight: 400,
    },
    { text: "Item Number 4.", weight: 700 },
    { text: " document, not a List C document.", weight: 400 },
  ],
};
