import { AbstractApiClient } from "../AbstractApiClient/AbstractApiClient";
import { postPreviewCheckData } from "./routes/postPreviewCheckData/postPreviewCheckData";
import {
  assertValidPicardSchema,
  PicardSchemaAssertionWithParams,
} from "./validation-types/assertValidPicardSchema";

export class PicardClient extends AbstractApiClient {
  public static AssertValidSchema: PicardSchemaAssertionWithParams =
    assertValidPicardSchema;
  public readonly PostPreviewCheckData = postPreviewCheckData(this.Instance);
}
