export const EWorfRoute = {
  CASE_FIELDS: "/api/case-fields",
  CLOSE_CASE: "/api/close-case",
  CREATE_CASE: "/api/create-case",
  DUPLICATE_CHECK: "/api/duplicate-check",
  LOGIN: "/api/login",
  TOKEN_REFRESH: "/api/token-refresh",
  TOKEN_VALIDATE: "/api/token-validate",
  TRIGGER_CASE_ON_ONBOARDING: "/api/trigger-case-on-onboarding",
} as const;

export type EWorfRoute = (typeof EWorfRoute)[keyof typeof EWorfRoute];
