export const getNoDirectDepositReason = (problems: string[]): string => {
  let reason = "Direct deposit is unavailable for this worker because they ";

  problems.forEach((problem, index) => {
    reason += problem;

    if (index === problems.length - 1) {
      reason += ".";
    } else {
      reason += ", and they ";
    }
  });

  return reason;
};
