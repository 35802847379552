import * as _moment from "moment";
type MomentInput = moment.MomentInput;
const moment = _moment.default;

export const getFormattedDate = (date?: MomentInput) => {
  if (!date) return null;

  return moment.utc(date).format("MMMM D, YYYY");
};

export const getFormattedDateWithTime = (date?: MomentInput) => {
  if (!date) return null;

  return moment.utc(date).local().format("h:mma on MMMM D, YYYY");
};

export const getISOFormattedDate = (date?: MomentInput) => {
  if (!date) return null;
  return moment.utc(date).format("YYYY-MM-DD");
};

export const getUSFormattedDate = (date?: MomentInput) => {
  if (!date) return null;
  return moment.utc(date).format("MM/DD/YYYY");
};

export const isValidDate = (date?: string) => moment(date).isValid();

export const getUSDateAndTime = (date: MomentInput) =>
  date && moment(date).format("MM/DD/YYYY h:mm a");

export const currentDate = new Date();
export const currentEffectiveDate = new Date().toISOString().split("T")[0];
export const today = moment().startOf("day");
export const minDob = new Date(
  currentDate.getFullYear() - 115,
  currentDate.getMonth(),
  currentDate.getDate()
);

export const maxDob = new Date(
  currentDate.getFullYear() - 14,
  currentDate.getMonth(),
  currentDate.getDate()
);

export const getISOFormattedDateFromJSDate = (date: Date) =>
  date.toISOString().substring(0, 10);

export const getStartOfCurrentYear = () => {
  const currentYear = currentDate.getFullYear().toString();
  return moment(currentYear).utc().format("YYYY-MM-DD");
};

/**
 * Use this to convert dates like "12-31-2023" to ISO-8601 format.
 *
 * Especially useful with dash delimited dates since the dash delimiter violates spec and results in parsing issues.
 *
 * @date string value of the date in mmddyyyy format (or with delimiter like mm-dd-yyyy)
 * @delimiter optional argument to specify if a delimiter is used in the string
 */
export const mmddyyyyToISO8601 = (date: string, delimiter?: string) => {
  const [month, day, year] = !delimiter
    ? [date.substring(0, 2), date.substring(2, 4), date.substring(4, 8)]
    : date.split(delimiter);
  return `${year}-${month}-${day}`;
};
