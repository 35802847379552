/**
 * To see how these statuses are inter-related:
 * https://www.notion.so/zeal-api/WIP-i9-and-e-verify-7e4b1de36061477d8ada3dbcac88e158?pvs=4#3dcf21bf66e24f0fa2b646c16e658284
 */

/**
 * Indicates the status of the e-verify case set by USCIS.
 */
export enum EEVerifyUSCISCaseStatus {
  DRAFT = "DRAFT",
  UNCONFIRMED_DATA = "UNCONFIRMED_DATA",
  PHOTO_MATCH = "PHOTO_MATCH",
  SCAN_AND_UPLOAD = "SCAN_AND_UPLOAD",
  MANUAL_REVIEW = "MANUAL_REVIEW",
  PENDING_REFERRAL = "PENDING_REFERRAL",
  REFERRED = "REFERRED",
  QUEUED = "QUEUED",
  EMPLOYMENT_AUTHORIZED = "EMPLOYMENT_AUTHORIZED",
  FINAL_NONCONFIRMATION = "FINAL_NONCONFIRMATION",
  CLOSE_CASE_AND_RESUBMIT = "CLOSE_CASE_AND_RESUBMIT",
  CLOSED = "CLOSED",
}

/**
 * Indicates the status of the e-verify case set by Zeal, visible internally.
 */
export enum EEVerifyInternalCaseStatus {
  IN_PROGRESS = "IN_PROGRESS",
  IN_REVIEW = "IN_REVIEW",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  CLOSED = "CLOSED",
}

/**
 * Indicates the status of the e-verify case set by Zeal, visible to end-users.
 */
export enum EEVerifyPublicCaseStatus {
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

/**
 * Indicates the actionable status of the e-verify case set by Zeal, visible internally.
 */
export enum EEVerifyInternalActionableStatus {
  PHOTO_MATCH = "PHOTO_MATCH",
  SCAN_AND_UPLOAD = "SCAN_AND_UPLOAD",
  PENDING_REFERRAL = "PENDING_REFERRAL",
  CLOSE_CASE_AND_RESUBMIT = "CLOSE_CASE_AND_RESUBMIT",
}

/**
 * Indicates the sub-status of the e-verify case set by the SSA or DHA, visible internally.
 */
export enum EEVerifyAgencyCaseSubStatus {
  PENDING_REFERRAL = "PENDING_REFERRAL",
  REFERRED = "REFERRED",
  CONTINUANCE = "CONTINUANCE",
  RESOLVED = "RESOLVED",
  NOT_REFERRED = "NOT_REFERRED",
}

/**
 * The reasons a case is closed
 */
export enum EEVerifyCaseClosureReasons {
  EMPLOYEE_QUIT = "EMPLOYEE_QUIT",
  PENDING_EMPLOYEE_ACTION_AUTO_CLOSE = "PENDING_EMPLOYEE_ACTION_AUTO_CLOSE",
  EMPLOYER_IS_NO_LONGER_CLIENT = "EMPLOYER_IS_NO_LONGER_CLIENT",
  EMPLOYER_INSTRUCTED_TO_CLOSE = "EMPLOYER_INSTRUCTED_TO_CLOSE",
  NO_LONGER_EMPLOYED = "NO_LONGER_EMPLOYED",
  INVALID_QUERY = "INVALID_QUERY",
  EMPLOYMENT_NOT_AUTHORIZED = "EMPLOYMENT_NOT_AUTHORIZED",
  SELF_TERMINATED = "SELF_TERMINATED",
  EMPLOYEE_NOT_TERMINATED = "EMPLOYEE_NOT_TERMINATED",
  FORCED_CLOSURE = "FORCED_CLOSURE",
  REVIEWED = "REVIEWED",
  OPERATOR_ERROR = "OPERATOR_ERROR",
  EMPLOYMENT_AUTHORIZED = "EMPLOYMENT_AUTHORIZED",
  DUPLICATE_CASE_ENTERED_BY_EMPLOYER = "DUPLICATE_CASE_ENTERED_BY_EMPLOYER",
  EXPIRED_DOCUMENT = "EXPIRED_DOCUMENT",
  EMPLOYER_INSTRUCTED_TO_CREATE_NEW_CASE = "EMPLOYER_INSTRUCTED_TO_CREATE_NEW_CASE",
  TECHNICAL_ISSUES_WITH_EVERIFY = "TECHNICAL_ISSUES_WITH_EVERIFY",
  EMPLOYEE_CONTINUES_TO_WORK_AFTER_EA = "EMPLOYEE_CONTINUES_TO_WORK_AFTER_EA",
  EMPLOYEE_CONTINUES_TO_WORK_AFTER_FNC = "EMPLOYEE_CONTINUES_TO_WORK_AFTER_FNC",
  EMPLOYEE_CONTINUES_TO_WORK_AFTER_NO_SHOW = "EMPLOYEE_CONTINUES_TO_WORK_AFTER_NO_SHOW",
  EMPLOYEE_CONTINUES_TO_WORK_AFTER_UNCONTESTED_TNC = "EMPLOYEE_CONTINUES_TO_WORK_AFTER_UNCONTESTED_TNC",
  EMPLOYEE_TERMINATED_FOR_FNC_RESULT = "EMPLOYEE_TERMINATED_FOR_FNC_RESULT",
  EMPLOYEE_TERMINATED_FOR_OTHER_REASONS = "EMPLOYEE_TERMINATED_FOR_OTHER_REASONS",
  EMPLOYEE_TERMINATED_FOR_NO_SHOW = "EMPLOYEE_TERMINATED_FOR_NO_SHOW",
  INCORRECT_DATA = "INCORRECT_DATA",
  EMPLOYEE_TERMINATED_FOR_UNCONTESTED_TNC = "EMPLOYEE_TERMINATED_FOR_UNCONTESTED_TNC",
  DUPLICATE_CASE_DATA_EXISTS = "DUPLICATE_CASE_DATA_EXISTS",
  EMPLOYER_INSTRUCTED_TO_CLOSE_BY_DHS = "EMPLOYER_INSTRUCTED_TO_CLOSE_BY_DHS",
  EMPLOYER_INSTRUCTED_TO_CLOSE_BY_SSA = "EMPLOYER_INSTRUCTED_TO_CLOSE_BY_SSA",
  OTHER = "OTHER",
  ADMINISTRATIVELY_CLOSED = "ADMINISTRATIVELY_CLOSED",
  INCOMPLETE_ADMIN_CLOSED = "INCOMPLETE_ADMIN_CLOSED",
  OTHER_FREE_TEXT = "OTHER_FREE_TEXT",
  EA_AUTO_CLOSE = "EA_AUTO_CLOSE",
}

/**
 * The reason a duplicate case is being opened
 */
export enum EEVerifyDuplicateContinueReasonCodes {
  REHIRE = "REHIRE",
  INCORRECT_INFORMATION_ENTERED = "INCORRECT_INFORMATION_ENTERED",
  DHS_ASKED_ME_TO_CREATE_A_NEW_CASE = "DHS_ASKED_ME_TO_CREATE_A_NEW_CASE",
  SSA_ASKED_ME_TO_CREATE_A_NEW_CASE = "SSA_ASKED_ME_TO_CREATE_A_NEW_CASE",
  PREVIOUS_CASE_RESULT_RESUBMIT = "PREVIOUS_CASE_RESULT_RESUBMIT",
  OTHER = "OTHER",
}

/**
 * The result of matching photos
 */
export enum EEVerifyPhotoMatchResults {
  NO_IMAGE = "no-image",
  NOT_MATCHING = "not-matching",
  MATCHING = "matching",
}
