import { TRANSACTION_CREATE } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  TCreateTransactionRequestSchema,
  TCreateTransactionResponseSchema,
} from "./createTransactionSchema";

export const createTransaction = (axios: IAxiosInstance) => {
  return async (body: TCreateTransactionRequestSchema) => {
    const response = await axios.noRetry.post<TCreateTransactionResponseSchema>(
      TRANSACTION_CREATE,
      body
    );
    return response.data;
  };
};
