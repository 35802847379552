import {
  EDisbursementMethod,
  TEmployeeDisbursementMethod,
} from "../workers-shared";
import * as DateUtils from "../dates/utils";
import * as MoneyUtils from "../money/utils";

export const getDisbursementLabel = (method: EDisbursementMethod) => {
  switch (method) {
    case "prepaid":
      return "Prepaid";
    case "download_check":
      return "Download check";
    default:
      return "Direct deposit";
  }
};

export const getDisbursementCheckInfoLabel = (
  method?: TEmployeeDisbursementMethod | EDisbursementMethod
) => {
  switch (method) {
    case "prepaid":
      return "Prepaid";
    case "download_check":
      return "Download check";
    case "mail_check":
      return "Mail check";
    case "direct_deposit":
      return "Direct deposit";
    default:
      return null;
  }
};

export const getReportingPeriod = ({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) => {
  if (!startDate && !endDate) return null;

  return `${DateUtils.getUSFormattedDate(
    startDate
  )} - ${DateUtils.getUSFormattedDate(endDate)}`;
};

export const getShiftWageLabel = (wage: number | undefined) =>
  `${MoneyUtils.getCentToDollarWSpace(wage)}/hour`;

export const getShiftHoursLabel = (hours: number | undefined) =>
  `${hours?.toFixed(2) ?? "__"} hours`;
