export const EDeductionType = {
  "401K": "401k",
  "403B": "403b",
  DEPENDENT_CARE_BENEFIT: "dependent_care_benefit",
  EMPLOYER_SPONSORED_HEALTH_COVERAGE: "employer_sponsored_health_coverage",
  GARNISHMENT: "garnishment",
  HSA: "hsa",
  MISCELLANEOUS: "miscellaneous",
  ROTH_401K: "roth_401k",
  ROTH_IRA: "roth_ira",
  SECTION_125: "section_125",
  SIMPLE_IRA: "simple_ira",
} as const;

export type EDeductionType =
  (typeof EDeductionType)[keyof typeof EDeductionType];
