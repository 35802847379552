import { SessionStorageKeys } from "@zeal/common";
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from "axios";

const MAX_RETRIES = 3;
const RETRY_STATUS_CODES = new Set<number>([502]);

export interface IBearerTokenAuth {
  readonly type: "bearer-token";
  readonly token: string;
}

export interface ITokenAuth {
  readonly type: "token";
  readonly token: string;
}

export interface ICookieAuth {
  readonly type: "cookie";
}

export type ClientAuthParameters = IBearerTokenAuth | ICookieAuth | ITokenAuth;

const getAuthHeaders = (auth: ClientAuthParameters): AxiosRequestHeaders => {
  if (auth.type === "token" || auth.type === "bearer-token") {
    return {
      Authorization: `Bearer ${auth.token}`,
    };
  }
  return {};
};

export type GetTokenFn = (key: SessionStorageKeys) => string | null;

export interface IApiClientOptions {
  readonly auth: ClientAuthParameters;
  readonly baseURL: string;
  readonly defaultRequestTimeoutMs?: number;
  readonly onInvalidResponseType?: (message: string) => Promise<void>;
  readonly getTokenFn?: GetTokenFn;
}

const defaultTimeoutMS = 60000;

export interface IAxiosInstance {
  readonly noRetry: AxiosInstance;
  readonly with502Retry: AxiosInstance;
}

export abstract class AbstractApiClient {
  private axiosInstance: AxiosInstance;
  private axiosInstanceWith502Retry: AxiosInstance;

  public get Instance(): IAxiosInstance {
    return {
      noRetry: this.axiosInstance,
      with502Retry: this.axiosInstanceWith502Retry,
    };
  }
  public constructor(options: IApiClientOptions) {
    const shouldUseCookieAuth = options.auth.type === "cookie";
    const instanceOptions: AxiosRequestConfig = {
      headers: getAuthHeaders(options.auth),
      baseURL: options.baseURL,
      timeout: options?.defaultRequestTimeoutMs
        ? options?.defaultRequestTimeoutMs
        : defaultTimeoutMS,
      withCredentials: shouldUseCookieAuth,
    };

    this.axiosInstance = axios.create(instanceOptions);
    this.axiosInstanceWith502Retry = axios.create(instanceOptions);
    this.configureSessionTokenInterceptor(
      this.axiosInstance,
      options.getTokenFn
    );
    this.configureSessionTokenInterceptor(
      this.axiosInstanceWith502Retry,
      options.getTokenFn
    );
    this.configureRetryInterceptor(this.axiosInstanceWith502Retry);
  }

  private configureSessionTokenInterceptor = (
    axiosInstance: AxiosInstance,
    getTokenFn?: GetTokenFn
  ) => {
    axiosInstance.interceptors.request.use((requestConfig) => {
      if (
        !Object.prototype.hasOwnProperty.call(globalThis, "sessionStorage") &&
        !getTokenFn
      ) {
        return requestConfig;
      }

      const getToken =
        getTokenFn ?? ((key: string) => sessionStorage.getItem(key));

      const sessionToken = getToken(SessionStorageKeys.SESSION_TOKEN);
      const primaryToken = getToken(SessionStorageKeys.PRIMARY_TOKEN);
      const secondaryToken = getToken(SessionStorageKeys.SECONDARY_TOKEN);

      return {
        ...requestConfig,
        headers: {
          ...(requestConfig.headers || {}),
          ...(sessionToken ? { "x-session-token": sessionToken } : {}),
          ...(primaryToken ? { "x-primary-token": primaryToken } : {}),
          ...(secondaryToken ? { "x-secondary-token": secondaryToken } : {}),
        },
      };
    });
  };

  private configureRetryInterceptor = (axiosInstance: AxiosInstance) => {
    axiosInstance.interceptors.response.use(undefined, async (error) => {
      const config = error.config;

      if (!config) {
        return Promise.reject(error);
      }

      if (RETRY_STATUS_CODES.has(error.response?.status)) {
        if (config.retryCount >= MAX_RETRIES) {
          return Promise.reject(error);
        }

        config.retryCount = config.retryCount ? config.retryCount + 1 : 1;

        const backoff = 2 ** config.retryCount * 1000;

        await new Promise((resolve) => setTimeout(resolve, backoff));

        return this.axiosInstance(config);
      }

      // Reject if the error is not a 502
      return Promise.reject(error);
    });
  };
}
