import { z } from "zod";

// base shifts used during reporting
export const shiftSchema = z.object({
  custom_name: z.string().nullish(),
  customerAccountID: z.string().nullish(),
  wcc_code: z.string().nullish(),
  name: z.string(),
  label: z.string(),
  amount: z.string(),
  description: z.string(),
  shift_date: z.string(),
  unformatted_amount: z.number(),
  workLocationID: z.string().optional(),
  workLocation: z.string().optional(),
  hours: z.string().nullish(),
});

// shifts organized by some key -- work locationID, employeeID etc.
const shiftsByKeySchema = z.record(z.string(), z.array(shiftSchema));

// corrections to be displayed
const shiftCorrectionSchema = z.object({
  type: z.string(),
  rate: z.string(),
  earned: z.string(),
});

export type Shift = z.infer<typeof shiftSchema>;
export type ShiftsByKey = z.infer<typeof shiftsByKeySchema>;
export type ShiftCorrections = z.infer<typeof shiftCorrectionSchema>;
