import { z } from "zod";
import { schemes, zSchema } from "../../schema-validation";
import { EEmploymentStatus } from "../../workers-shared";
import { ZealErrorAlias } from "../../errors/ZealErrors";
import { EPaySchedule } from "../EPaySchedule";
import { employeeAddressSchema } from "./employeeAddress";
import { employeeDatesSchema } from "./employeeDates";
import { employeeWagesSchema } from "./employeeWages";
import { ETermReason } from "../ETermReason";
import { benefitsClassSchema } from "../../employees/EBenefitsClass";

export const updateEmployeeSchemaBase = z
  .object(
    {
      companyID: schemes.companyID(),
      testMode: z.boolean(),
      employeeID: schemes.mongoObjectId(
        "employeeID",
        ZealErrorAlias.EMPLOYEE_ID_NOT_OBJECTID.message
      ),
      first_name: schemes.formattedName().optional(),
      middle_initial: z.string().nullable().optional(),
      last_name: schemes.formattedName().optional(),
      employment_status: z.nativeEnum(EEmploymentStatus).optional(),
      term_reason: zSchema.nativeEnum(ETermReason).optional(),
      email: schemes.email().optional(),
      title: schemes
        .nonEmptyString("Title must have a minimum of 1 character")
        .optional(),
      phone_number: schemes.phone().optional(),
      default_pay_schedule: z.nativeEnum(EPaySchedule).optional(),
      autopilot_on: z.boolean().optional(),
      ssn: schemes.ssn().optional(),
      workLocationID: schemes
        .mongoObjectId(
          "workLocationID",
          ZealErrorAlias.WORK_LOCATION_NOT_FOUND.message
        )
        .optional(),
      external_id: schemes
        .externalID(ZealErrorAlias.INVALID_EXTERNAL_ID.message)
        .optional(),
      metadata: schemes.metadata().nullable().optional(),
      is_943: z.boolean().optional(),
      is_scheduleH: z.boolean().optional(),
      is_regular: z.boolean().optional(),
      paperless_w2: z.boolean().optional(),
      benefits_class: benefitsClassSchema.optional(),
    },
    { required_error: "No employee object found in the body of request" }
  )
  .merge(employeeDatesSchema)
  .merge(employeeAddressSchema)
  .merge(employeeWagesSchema);

export const updateEmployeeSchema = updateEmployeeSchemaBase.omit({
  testMode: true,
  companyID: true,
});

export type UpdateEmployee = z.infer<typeof updateEmployeeSchema>;
