import { zSchema } from "../../../schema-validation";

export const postFundingSourceRequestSchema = zSchema.object({
  account_number: zSchema.string(),
  account_type: zSchema.enum(["checking", "savings"]),
  company_id: zSchema.string(),
  customer_account_id: zSchema.string(),
  partner_id: zSchema.string(),
  routing_number: zSchema.string(),
  testMode: zSchema.boolean(),
});

export type TPostFundingSourceRequestSchema = zSchema.infer<
  typeof postFundingSourceRequestSchema
>;

export const postFundingSourceResponseSchema = zSchema.object({
  company_id: zSchema.string(),
  customer_account_id: zSchema.string(),
  funding_source_id: zSchema.string(),
  partner_id: zSchema.string(),
});

export type TPostFundingSourceResponseSchema = zSchema.infer<
  typeof postFundingSourceResponseSchema
>;
