import { AbstractApiClient } from "../AbstractApiClient/AbstractApiClient";
import { companyAddAch } from "./routes/companyAddAch/companyAddAch";
import { companyKybOnboard } from "./routes/companyKybOnboard/companyKybOnboard";
import { companyOnboard } from "./routes/companyOnboard/companyOnboard";
import { createCustodyNode } from "./routes/createCustodyNode/createCustodyNode";
import { createPayCard } from "./routes/createPayCard/createPayCard";
import { createTransaction } from "./routes/createTransaction/createTransaction";
import { customerAccountAddAch } from "./routes/customerAccountAddAch/customerAccountAddAch";
import { customerAccountOnboard } from "./routes/customerAccountOnboard/customerAccountOnboard";
import { getCompanies } from "./routes/getCompany/getCompanies";
import { getCompany } from "./routes/getCompany/getCompany";
import { getCompanyAccounts } from "./routes/getCompanyAccounts/getCompanyAccounts";
import { getCompanyMicroDeposits } from "./routes/getCompanyMicroDeposits/getCompanyMicroDeposits";
import { getCompanyStatus } from "./routes/getCompanyStatus/getCompanyStatus";
import { getCustomerAccountMicroDeposits } from "./routes/getCustomerAccountMicroDeposits/getCustomerAccountMicroDeposits";
import { getEmployeeWatchlistStatus } from "./routes/getEmployeeWatchlistStatus/getEmployeeWatchlistStatus";
import { getLedgerForUser } from "./routes/getLedgerForUser/getLedgerForUser";
import { getStateAgencyInfo } from "./routes/getStateAgencyInfo/getStateAgencyInfo";
import { getStateAgencyTransactionsBulk } from "./routes/getStateAgencyTransactionsBulk/getStateAgencyTransactionsBulk";
import { getTransactionEvents } from "./routes/getTransactionEvents/getTransactionEvents";
import { getTransactionStatus } from "./routes/getTransactionStatus/getTransactionStatus";
import { getUserData } from "./routes/getUserData/getUserData";
import { migrateOneCompany } from "./routes/migrateCompanies/migrateOneCompany";
import { getUnmigratedWorkers } from "./routes/migrateWorkers/getUnmigratedWorkers";
import { migrateOneWorker } from "./routes/migrateWorkers/migrateOneWorker";
import { reconcileBankAccounts } from "./routes/reconcileBankAccounts/reconcileBankAccounts";
import { resetCompanyMicroDeposits } from "./routes/resetCompanyMicroDeposits/resetCompanyMicroDeposits";
import { runAllKYC } from "./routes/runAllKYC/runAllKYC";
import { runCompanyKYB } from "./routes/runCompanyKYB/runCompanyKYB";
import { runKYC } from "./routes/runKYC/runKYC";
import { triggerCompanyMicroDeposits } from "./routes/triggerCompanyMicroDeposits/triggerCompanyMicroDeposits";
import { triggerCustomerAccountMicroDeposits } from "./routes/triggerCustomerAccountMicroDeposits/triggerCustomerAccountMicroDeposits";
import { updateCompanyNode } from "./routes/updateCompanyNode/updateCompanyNode";
import { updateKYCInfo } from "./routes/updateKYCInfo/updateKYCInfo";
import { updateTransactionStatuses } from "./routes/updateTransactionStatuses/updateTransactionStatuses";
import { verifyCompanyMicroDeposits } from "./routes/verifyCompanyMicroDeposits/verifyCompanyMicroDeposits";
import { verifyCustomerAccountMicroDeposits } from "./routes/verifyCustomerAccountMicroDeposits/verifyCustomerAccountMicroDeposits";
import {
  assertValidOdoSchema,
  OdoSchemaAssertionWithParams,
} from "./validation-types/assertValidOdoSchema";

export class OdoClient extends AbstractApiClient {
  public static AssertValidSchema: OdoSchemaAssertionWithParams =
    assertValidOdoSchema;
  public readonly CompanyAddAch = companyAddAch(this.Instance);
  public readonly CompanyOnboard = companyOnboard(this.Instance);
  public readonly CompanyKybOnboard = companyKybOnboard(this.Instance);
  public readonly CreateCustodyNode = createCustodyNode(this.Instance);
  public readonly CreatePayCard = createPayCard(this.Instance);
  public readonly CreateTransaction = createTransaction(this.Instance);
  public readonly CustomerAccountAddAch = customerAccountAddAch(this.Instance);
  public readonly CustomerAccountOnboard = customerAccountOnboard(
    this.Instance
  );
  public readonly GetCompanyAccounts = getCompanyAccounts(this.Instance);
  public readonly GetEmployeeWatchlistStatus = getEmployeeWatchlistStatus(
    this.Instance
  );
  public readonly GetCompanies = getCompanies(this.Instance);
  public readonly GetCompany = getCompany(this.Instance);
  public readonly GetCompanyMicroDeposits = getCompanyMicroDeposits(
    this.Instance
  );
  public readonly GetCompanyStatus = getCompanyStatus(this.Instance);
  public readonly GetCustomerAccountMicroDeposits =
    getCustomerAccountMicroDeposits(this.Instance);
  public readonly GetStateAgencyInfo = getStateAgencyInfo(this.Instance);
  public readonly GetStateAgencyTransactionsBulk =
    getStateAgencyTransactionsBulk(this.Instance);
  public readonly GetTransactionStatus = getTransactionStatus(this.Instance);
  public readonly GetUnmigratedWorkers = getUnmigratedWorkers(this.Instance);
  public readonly GetUserData = getUserData(this.Instance);
  public readonly MigrateOneCompany = migrateOneCompany(this.Instance);
  public readonly MigrateOneWorker = migrateOneWorker(this.Instance);
  public readonly ReconcileBankAccounts = reconcileBankAccounts(this.Instance);
  public readonly ResetCompanyMicroDeposits = resetCompanyMicroDeposits(
    this.Instance
  );
  public readonly RunAllKYC = runAllKYC(this.Instance);
  public readonly RunKYC = runKYC(this.Instance);
  public readonly TriggerCompanyMicroDeposits = triggerCompanyMicroDeposits(
    this.Instance
  );
  public readonly TriggerCustomerAccountMicroDeposits =
    triggerCustomerAccountMicroDeposits(this.Instance);
  public readonly UpdateCompanyNode = updateCompanyNode(this.Instance);
  public readonly UpdateKYCInfo = updateKYCInfo(this.Instance);
  public readonly UpdateTransactionStatuses = updateTransactionStatuses(
    this.Instance
  );
  public readonly VerifyCompanyMicroDeposits = verifyCompanyMicroDeposits(
    this.Instance
  );
  public readonly VerifyCustomerAccountMicroDeposits =
    verifyCustomerAccountMicroDeposits(this.Instance);
  public readonly RunCompanyKYB = runCompanyKYB(this.Instance);
  public readonly GetLedgerForUser = getLedgerForUser(this.Instance);
  public readonly GetTransactionEvents = getTransactionEvents(this.Instance);
}
