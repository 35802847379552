import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const getCompanyDebits = (axios: IAxiosInstance) => {
  return async (
    params: ISchema["#/definitions/ICompanyDebitsQueryParameters"]
  ) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetCompanyDebitsResponse"]
    >(`/api/companyDebits`, {
      params,
    });

    if (!response?.data?.success) {
      throw new Error("Something went wrong");
    }
    return response?.data?.data;
  };
};
