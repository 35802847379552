import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  IUploadOneWorkerRequest,
  ICreateEmployeeResponse,
} from "./createEmployee.schema";

export const createEmployee = (axios: IAxiosInstance) => {
  return async (formData: IUploadOneWorkerRequest) => {
    const { dob, start_date, salary_firstDate } = formData;
    // Ideally the schema would require a date string with no timezone
    const formDataWithoutTimezones = {
      ...formData,
      dob: dob ? dob.toISOString().substring(0, 10) : dob,
      start_date: start_date
        ? start_date.toISOString().substring(0, 10)
        : start_date,
      salary_firstDate: salary_firstDate
        ? salary_firstDate.toISOString().substring(0, 10)
        : salary_firstDate,
    };
    const response = await axios.noRetry.post<ICreateEmployeeResponse>(
      `/api/uploadOneWorker`,
      formDataWithoutTimezones
    );
    const resData = response.data;
    if (resData.success) {
      return resData.success;
    }
    throw new Error("Something went wrong");
  };
};
