export class ReadonlySet<T> {
  private _set: Set<T>;

  constructor(iterable?: Iterable<T>) {
    this._set = new Set(iterable);
  }

  [Symbol.iterator](): IterableIterator<T> {
    return this._set[Symbol.iterator]();
  }

  has(value: T): boolean {
    return this._set.has(value);
  }

  values(): IterableIterator<T> {
    return this._set.values();
  }

  size(): number {
    return this._set.size;
  }

  toMutable(): Set<T> {
    return new Set([...this]);
  }
}
