export interface IGrossupShift {
  readonly calculated_gross_amount?: number;
  readonly desired_net: number;
}

export interface IShiftPayout {
  readonly amount_per_unit: number;
  readonly amount: number;
  readonly custom_name?: string;
  readonly hours: number;
  readonly units: number;
  readonly wage: number;
}

export enum ShiftStatus {
  PENDING = "pending",
  PRE_PROCESSED = "pre-processed",
  PROCESSED = "processed",
  VOIDED = "voided",
}

export interface IShift {
  readonly advance?: Pick<IShiftPayout, "amount" | "custom_name">;
  readonly bonus?: Pick<IShiftPayout, "amount" | "custom_name">;
  readonly charged_tips?: Pick<IShiftPayout, "amount" | "custom_name">;
  readonly cash_tips?: Pick<IShiftPayout, "amount" | "custom_name">;
  readonly correction?: Pick<IShiftPayout, "amount" | "custom_name"> & {
    hours?: number;
  };
  readonly commission?: Pick<IShiftPayout, "amount" | "custom_name">;
  readonly commodity?: Pick<IShiftPayout, "amount" | "custom_name">;
  readonly companyID?: string;
  readonly createdAt?: string;
  readonly discretionary_bonus?: Pick<IShiftPayout, "amount" | "custom_name">;
  readonly doubletime?: Pick<IShiftPayout, "hours" | "wage" | "custom_name">;
  /**
   * @minLength 32
   * @maxLength 32
   */
  readonly employeeCheckID?: string;

  /**
   * @minLength 24
   * @maxLength 24
   */
  readonly employeeID?: string;
  readonly first_name?: string;
  readonly flat?: Pick<IShiftPayout, "hours" | "amount" | "custom_name">;
  readonly gross_up?: IGrossupShift;
  readonly holiday?: Pick<IShiftPayout, "hours" | "wage" | "custom_name">;
  readonly hourly?: Pick<IShiftPayout, "hours" | "wage" | "custom_name">;
  readonly last_name?: string;
  readonly mark_for_review?: boolean;
  readonly metadata?: {
    readonly isDeleted?: boolean;
    readonly isRegularEarning?: boolean;
  };
  readonly middle_initial?: string;
  readonly nondiscretionary_bonus?: Pick<
    IShiftPayout,
    "amount" | "custom_name"
  >;
  readonly overtime?: Pick<IShiftPayout, "hours" | "wage" | "custom_name">;
  readonly piecework?: Pick<
    IShiftPayout,
    "hours" | "amount_per_unit" | "units" | "custom_name"
  >;
  readonly pto?: Pick<IShiftPayout, "hours" | "wage" | "custom_name">;
  readonly reimbursement?: Pick<IShiftPayout, "amount" | "custom_name">;

  /**
   * @minLength 24
   * @maxLength 24
   */
  readonly reportingPeriodID?: string;

  /**
   * @minLength 24
   * @maxLength 24
   */
  readonly shiftID?: string;
  readonly sick?: Pick<IShiftPayout, "hours" | "wage" | "custom_name">;
  readonly status?: ShiftStatus;
  readonly time?: string;
  readonly two_percent_shareholder_healthcare_reimbursement?: Pick<
    IShiftPayout,
    "amount" | "custom_name"
  >;
  readonly group_term_life_insurance_cost_over_50000?: Pick<
    IShiftPayout,
    "amount" | "custom_name"
  >;
  readonly updatedAt?: string;
  readonly vacation?: Pick<IShiftPayout, "hours" | "wage" | "custom_name">;
  readonly wcc_code?: string | null;
  /**
   * @minLength 24
   * @maxLength 24
   */
  readonly workLocationID?: string;
  /**
   * @minLength 24
   * @maxLength 24
   */
  readonly _id?: string;
  readonly customerAccountID?: string;
  readonly flsa_ot_exempt?: boolean;
}
