import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";
import { IUpdateWorkerBankInformation } from "./updateWorkerBankInformation.jsonschema";

export const updateWorkerBankInformation = (axios: IAxiosInstance) => {
  return async (body: IUpdateWorkerBankInformation) => {
    try {
      const response = await axios.noRetry.post<
        ISchema["#/definitions/IUpdateWorkerBankInformationResponse"]
      >(`/api/updateWorkerBankInformation`, body);
      const { data } = response.data;
      return data;
    } catch (error: any) {
      throw new Error(
        (error?.response.data.errors &&
          error.response.data.errors[0].message) ||
          "Error updating bank information. Please contact support."
      );
    }
  };
};
