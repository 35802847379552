export enum JobServices {
  PreviewCheckDate = "preview-check-date",
  PreviewChecks = "preview-checks",
  PreviewChecksFLSA = "preview-checks-flsa",
  Reports = "reports",
  CompassStateFilings = "compass-state-filings",
  CompassW2Filings = "compass-w2-filings",
  CompassW2Approval = "compass-w2-approval",
  Compass1099Filings = "compass-1099-filings",
  Compass1099Approval = "compass-1099-approval",
  CompassIrsForms = "compass-irs-forms",
  CompassReferenceFiles = "compass-reference-files",
  CompassMasterHeaders = "compass-master-headers",
  CompassPaLocalsReport = "compass-pa-locals-report",
  CompassCreditReductionReport = "compass-credit-reduction",
}
