import { payment_creation_types, schemes, zSchema } from "@zeal/common";

export const createTransactionRequestSchema = zSchema.object({
  addenda: zSchema.string().or(zSchema.array(zSchema.string())).optional(),
  amount: zSchema.number().optional(),
  batch_number: zSchema.number().optional(),
  check_id: zSchema.string().optional(),
  company_id: schemes.uuid().optional(),
  company_name: zSchema.string().optional(),
  destinations_and_amounts: zSchema
    .array(
      zSchema.object({
        net_amount: zSchema.number(),
        paystub_id: zSchema.string(),
        to_user_id: zSchema.string(),
        zeal_id: zSchema.string().optional(),
      })
    )
    .optional(),
  enable_idempotency: zSchema.boolean().optional(),
  employer_taxes: zSchema.number().optional(),
  extra_meta: zSchema
    .object({
      amounts: zSchema.array(zSchema.number()),
      amount_types: zSchema.array(
        zSchema.enum([
          "TAX",
          "PENALTY",
          "INTEREST",
          "STATE",
          "LOCAL",
          "CITY",
          "FEE",
          "ESCROW",
          "ESTIMATED",
          "ANNUAL",
          "EXTENTION",
          "AUDIT",
          "LOAN",
          "UNDER_PROTEST",
          "RETURN",
          "BILL",
          "PRIOR_OVERPAYMENT",
          "CREDIT_MEMO",
          "DISOUNT",
          "LEVY",
          "GARNISHMENT",
          "1",
          "2",
          "3",
        ])
      ),
      payment_code: zSchema.string(),
      tax_period_end_date: zSchema.string(),
      taxpayer_verification: zSchema.any().optional(),
      tin: zSchema.string(),
    })
    .optional(),
  from_user: zSchema.string(),
  net_amount: zSchema.number().optional(),
  note: zSchema.string().optional(),
  partner_id: schemes.uuid().optional(),
  partner_name: zSchema.string().optional(),
  same_day: zSchema.boolean().optional(),
  to_user: zSchema.string().optional(),
  type_of_payment: zSchema.nativeEnum(payment_creation_types).optional(),
  zeal_id: zSchema.string().optional(),
  metadata: schemes.metadata().nullable().optional(),
});

export type TCreateTransactionRequestSchema = zSchema.infer<
  typeof createTransactionRequestSchema
>;

export const createTransactionResponseSchema = zSchema.any();

export type TCreateTransactionResponseSchema = zSchema.infer<
  typeof createTransactionResponseSchema
>;
