import { z } from "zod";
import { MediaType, Disbursement, DisbursementStatus } from "../enums";
import { RouteMap } from "./customerReporting";
import { Company } from "./company";
import { shiftSchema } from "./shift";

export const customPayrollJournalFieldsSchema = z.object({
  customer_account: z.boolean().optional(),
  first_name: z.boolean().optional(),
  last_name: z.boolean().optional(),
  employee_taxes: z.boolean().optional(),
  employer_taxes: z.boolean().optional(),
  deductions: z.boolean().optional(),
  net_pay: z.boolean().optional(),
  earnings: z.boolean().optional(),
  disbursement: z.boolean().optional(),
  disbursement_status: z.boolean().optional(),
  gross_pay: z.boolean().optional(),
  employeeCheckID: z.boolean().optional(),
  employer_check_id: z.boolean().optional(),
  odo_id: z.boolean().optional(),
});

export const payrollJournalParamsSchema = z.object({
  companyID: z.string(),
  customer_account_ids: z.array(z.string()).optional(),
  end_date: z.string().optional(),
  fields: customPayrollJournalFieldsSchema.optional(),
  has_custom_flag: z.boolean(),
  has_live_key: z.boolean(),
  include_migrated: z.boolean(),
  paystub_ids: z.array(z.string()),
  start_date: z.string().optional(),
});

export const payrollJournalRequestHandlerSchema = z.object({
  companyID: z.string(),
  end_date: z.string().optional(),
  include_migrated: z.boolean(),
  media_type: z.enum([MediaType.Csv, MediaType.Pdf]),
  paystub_ids: z.array(z.string()),
  start_date: z.string().optional(),
});

export const customPayrollJournalRequestHandlerSchema = z.object({
  companyID: z.string(),
  customer_account_ids: z.array(z.string()).optional(),
  end_date: z.string().optional(),
  fields: customPayrollJournalFieldsSchema.optional(),
  include_migrated: z.boolean(),
  media_type: z.enum([MediaType.Csv, MediaType.Pdf]),
  paystub_ids: z.array(z.string()),
  start_date: z.string().optional(),
});

export const payrollJournalSubHeaders = z.object({
  pay: z.boolean(),
  deduction: z.boolean(),
  er_taxes: z.boolean(),
  ee_taxes: z.boolean(),
  earnings: z.boolean(),
  disbursement: z.boolean(),
  gross_pay: z.boolean(),
});

export const payrollJournalColumnHeaders = z.array(
  z.object({
    key: z.string(),
    row: z.union([z.string(), z.array(z.string())]),
  })
);

export type PayrollJournalColumnHeaders = z.infer<
  typeof payrollJournalColumnHeaders
>;

export const payrollJournalHeaders = z.object({
  has_custom_fields: z.boolean(),
  custom_fields: customPayrollJournalFieldsSchema.nullable(),
  headers: payrollJournalColumnHeaders,
  sub_headers: payrollJournalSubHeaders,
});

export const withholdingSchema = z.object({
  name: z.string(),
  amount: z.string(),
});

export type PayrollJournalWithholdings = z.infer<typeof withholdingSchema>;

export const taxListSchema = z.object({
  name: z.string(),
  codename: z.string(),
});

export const deductionListSchema = z.object({
  name: z.string(),
  amount: z.string(),
});

const earningsSchema = shiftSchema.omit({
  label: true,
  description: true,
  shift_date: true,
  unformatted_amount: true,
});

export type PayrollJournalEarnings = z.infer<typeof earningsSchema>;

export const formattedCheckForPayrollJournalSchema = z.object({
  customer_account_id: z.string().optional(),
  customer_account_business_name: z.string().optional(),
  customer_account_code: z.string().optional(),
  employee_id: z.string(),
  external_id: z.string().nullish(),
  street_address: z.string(),
  city: z.string(),
  zip_code: z.string(),
  state: z.string(),
  employee_check_id: z.string(),
  check_date: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  full_name: z.string(),
  gross_pay: z.string().optional(),
  net_pay: z.string().optional(),
  disbursement: z.nativeEnum(Disbursement).optional(),
  disbursement_status: z.nativeEnum(DisbursementStatus).optional(),
  total_employee_tax: z.string(),
  total_employer_tax: z.string(),
  total_deductions: z.string(),
  employee_taxes: z.array(withholdingSchema).optional(),
  employer_taxes: z.array(withholdingSchema).optional(),
  deductions: z.array(withholdingSchema),
  employer_check_id: z.string().optional(),
  odo_id: z.string().optional(),
  work_location_id_display: z.string().nullish(),
  work_location_name_display: z.string().nullish(),
  workers_comp_code_display: z.string().nullish(),
  earnings: z.array(earningsSchema).optional(),
});

export type CustomPayrollFields = z.infer<
  typeof customPayrollJournalFieldsSchema
>;
export type FormattedCheckForPayrollJournal = z.infer<
  typeof formattedCheckForPayrollJournalSchema
>;
export type PayrollJournalHeaders = z.infer<typeof payrollJournalHeaders>;
export type PayrollJournalSubHeaders = z.infer<typeof payrollJournalSubHeaders>;
export type PayrollJournalParams = z.infer<typeof payrollJournalParamsSchema>;

export type Withholding = z.infer<typeof withholdingSchema>;
export type TaxList = z.infer<typeof taxListSchema>;
export type TaxTypeGroup = Record<string, TaxList[]>;

export type PayrollJournalCsvResponseShape = {
  kind: RouteMap.payroll_journal | RouteMap.custom_payroll_journal;
  checks: FormattedCheckForPayrollJournal[];
  shifts: string[];
  has_custom_fields?: boolean;
  custom_fields?: Pick<
    CustomPayrollFields,
    | "customer_account"
    | "employee_taxes"
    | "employer_taxes"
    | "gross_pay"
    | "net_pay"
    | "odo_id"
    | "deductions"
    | "employer_check_id"
    | "earnings"
    | "disbursement"
  >;
  deductions: string[];
  taxes: {
    _id: string;
    taxes: TaxList[];
  }[];
} & Pick<PayrollJournalHeaders, "headers" | "sub_headers">;

export type PayrollJournalCsvMapperResponse = Omit<
  PayrollJournalCsvResponseShape,
  "taxes"
> & { taxes: Record<string, string[]> };

export type CheckDateDeduction<T = number> = {
  name: string;
  deduction_type: string;
  employee_contribution: T;
  employer_contribution: T;
};

export type CheckDateDeductions<T> = {
  check_date: string;
  deductions: CheckDateDeduction<T>[];
}[];

export type CheckDateChecks<T> = {
  check_date: string;
  checks: FormattedCheckForPayrollJournal[];
} & CheckDateCheckTaxTotals<T>;

export type CheckDateTaxTotal<T = number> = {
  paidBy: string;
  codename: string;
  name: string;
  amount: T;
};
export type CheckDateTaxTotals<T> = {
  check_date: string;
  taxes: Omit<CheckDateTaxTotal<T>, "codename">[];
}[];

export type CheckDateCheckTaxTotals<T = number> = {
  total_employee_taxes: T;
  total_employer_taxes: T;
  total_deductions: T;
  total_net_pay: T;
  total_gross_pay: T;
};

export type CheckDateDisbursement<T = number> = {
  method: string;
  total: T;
};
export type CheckDateDisbursements<T> = {
  check_date: string;
  disbursement: CheckDateDisbursement<T>[];
}[];

export type PayrollJournalPdfResponseShape<T = number> = {
  kind: RouteMap.payroll_journal | RouteMap.custom_payroll_journal;
  start_date: string;
  end_date: string;
  check_date_checks: CheckDateChecks<T>[];
  check_date_tax_totals: CheckDateCheckTaxTotals<T>;
  check_date_disbursements: CheckDateDisbursements<T>;
  check_date_deductions: CheckDateDeductions<T>;
  total_deductions: T;
  total_employee_taxes: T;
  total_employer_taxes: T;
  total_gross_pay: T;
  total_net_pay: T;
} & PayrollJournalHeaders;

export type PayrollJournalPdfData = {
  tax_totals: CheckDateTaxTotal[];
  disbursement_totals: CheckDateDisbursement[];
  deduction_totals: CheckDateDeduction[];
  checks: FormattedCheckForPayrollJournal[];
} & CheckDateCheckTaxTotals<number>;

export type EmployerCheckTotals<T = number> = {
  total_employee_taxes: T;
  total_employer_taxes: T;
  total_net_pay: T;
  total_gross_pay: T;
  total_company_debit: T;
  total_company_cash_requirement: T;
};

export type PaystubIDsResponse = {
  next_cursor: string;
  paystub_ids: string[];
};

export type Earnings = z.infer<typeof earningsSchema>;

export type PayrollJournalPDFOpts = Company & {
  report_name?: string;
  check_date: string;
  start_date: string;
  end_date: string;
  checks: FormattedCheckForPayrollJournal[];
  has_custom_fields?: boolean;
  headers?: PayrollJournalColumnHeaders;
  sub_headers?: PayrollJournalSubHeaders;
};
