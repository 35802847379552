import { EKYCStatus, isApproved } from "../../kyc/EKYCStatus";
import { IFormTemplateJSON } from "../../forms";
import {
  FormKey,
  OnboardingSteps,
  StageConfig,
  IStages,
  IStage,
} from "./types";
import {
  EAuthorizedRepresentativeDecision,
  IAuthorizedRepresentative,
} from "../../i9-everify";

export interface IGetOnboardingFlowParams {
  authorizedRepresentatives: IAuthorizedRepresentative[] | undefined;
  isI9Only: boolean;
  kyc_status?: EKYCStatus;
  onboarded?: boolean;
  allowOnboardedEmployee?: boolean;
  stages?: IStages;
}

export const shouldDefaultToFinishOnboardingWhenI9Only = (
  authorizedRepresentatives: IAuthorizedRepresentative[] | undefined
): boolean => {
  // Do not default to the finish onboarding page if the worker is yet to finish the I9 flow
  if (!authorizedRepresentatives || authorizedRepresentatives.length === 0) {
    return false;
  }

  const latestAuthorizedRepresentativeDecision =
    authorizedRepresentatives[authorizedRepresentatives.length - 1].decision;

  // Return whether the authorized representative has already approved
  return (
    latestAuthorizedRepresentativeDecision ===
    EAuthorizedRepresentativeDecision.APPROVED
  );
};

export const getOnboardingFlow = (
  params: IGetOnboardingFlowParams,
  applicableFormTemplates: IFormTemplateJSON[] = []
): Array<IStage> => {
  const {
    allowOnboardedEmployee = false,
    authorizedRepresentatives,
    isI9Only,
    kyc_status,
    onboarded,
    stages,
  } = params;

  /**
   * Default to the finish onboarding page if the worker is already onboarded and
   * KYC approved, with the EXCEPTION of the I9-only flow:
   * Companies that use the new I9 flow can request I9-only onboarding links for a
   * worker after the worker has finished onboarding (without I9).
   */
  const shouldForceOnboardedStep =
    onboarded &&
    !allowOnboardedEmployee &&
    (isI9Only
      ? shouldDefaultToFinishOnboardingWhenI9Only(authorizedRepresentatives)
      : true);

  if (shouldForceOnboardedStep) {
    return [OnboardingSteps.Onboarded];
  }

  const i9FormTemplate = applicableFormTemplates.find(
    (formTemplate) => formTemplate.form_type === "i9"
  );

  const formKeys = applicableFormTemplates
    .filter((formTemplate) => formTemplate.form_type !== "i9")
    .map((formTemplate) => formTemplate.formTemplateID) as FormKey[];

  return [
    ...(stages?.profile_info ? [OnboardingSteps.Profile] : []),
    ...(stages?.employee_acct ? [OnboardingSteps.Account] : []),
    ...(stages?.id_scan ? [OnboardingSteps.Verification] : []),
    ...(isApproved(kyc_status) ? formKeys : []),
    ...(stages?.i9 && i9FormTemplate
      ? ([i9FormTemplate?.formTemplateID] as FormKey[])
      : []),
    ...(stages?.health_insurance ? [OnboardingSteps.HealthInsurance] : []),
    /**
     * Filter out the bank step if it's the i9-only flow.
     * Else, always show the bank step if EE has not been onboarded.
     */
    ...(!onboarded && !isI9Only ? [OnboardingSteps.Bank] : []),
    OnboardingSteps.Onboarded,
  ];
};

export const addNextKeys = (stages: StageConfig[]) =>
  stages.map((stage, idx) => ({
    ...stage,
    nextKey: idx < stages.length - 1 ? stages[idx + 1].key : undefined,
  }));
