import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IDeleteCheckRequestBody } from "./deleteCheck.schema";

export const deleteCheck = (axios: IAxiosInstance) => {
  return async ({
    employeeCheckID,
    keepEmptyRun = false,
  }: IDeleteCheckRequestBody) => {
    const response = await axios.noRetry.post(`/api/deleteCheck`, {
      employeeCheckID,
      keepEmptyRun,
    });

    const {
      data: { success },
    } = response;

    if (!success) {
      throw new Error(`Failed to delete check`);
    }

    return {};
  };
};
