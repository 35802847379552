export type ZealError = {
  message: string;
  status: number;
  code: number;
  metadata?: object;
};
export type ZealErrorCode = keyof typeof ZealErrors;
export type ZealErrorAliasName = keyof typeof ZealErrorAlias;

// https://docs..com/reference/error-codes
export const ZealErrors = {
  0: {
    message:
      "This account does not have access to live payroll. You may only use Test Keys/Test Mode for now.",
    status: 403,
    code: 0,
  },
  1: {
    message: "No Authorization Header.",
    status: 401,
    code: 1,
  },
  2: {
    message: "No companyID in the query of request.",
    status: 400,
    code: 2,
  },
  3: {
    message: "No companyID in the body of request.",
    status: 400,
    code: 3,
  },
  4: {
    message: "No company with this companyID exists..",
    status: 400,
    code: 4,
  },
  5: {
    message: "Error with key.",
    status: 401,
    code: 5,
  },
  6: {
    message: "No partnerID in the body/query of request",
    status: 400,
    code: 6,
  },
  7: {
    message: "No Partner with this partner ID exists",
    status: 400,
    code: 7,
  },
  8: {
    message: "Error with key",
    status: 401,
    code: 8,
  },
  9: {
    message: "Invalid format for time given in query.",
    status: 400,
    code: 9,
  },
  10: {
    message: "Reporting Period with this ID does not exist.",
    status: 400,
    code: 10,
  },
  11: {
    message: "No 'shifts' array found in the body of request.",
    status: 400,
    code: 11,
  },
  12: {
    message:
      "Worker(s) with given employeeID(s) in the body of the request does not exist.",
    status: 400,
    code: 12,
  },
  13: {
    message:
      "Object(s) is missing required fields or has improperly formatted data",
    status: 400,
    code: 13,
  },
  14: {
    message: "Shift(s) have been assigned wages below minimum wage",
    status: 400,
    code: 14,
  },
  15: {
    message: "Shift(s) with given shiftID(s) is not found for this user",
    status: 404,
    code: 15,
  },
  16: {
    message: "No employerCheckID found in the query of request",
    status: 400,
    code: 16,
  },
  17: {
    message: "Object with this ID does not exist",
    status: 400,
    code: 17,
  },
  18: {
    message: "No end date found in query of request",
    status: 400,
    code: 18,
  },
  19: {
    message: "No start date found in query of request",
    status: 400,
    code: 19,
  },
  20: {
    message:
      "Dates provided in query of request are invalid/inappropriately formatted",
    status: 400,
    code: 20,
  },
  21: {
    message: "End Date occurs before Start Date",
    status: 400,
    code: 21,
  },
  22: {
    message: "No 'employees' array found in the body of request",
    status: 400,
    code: 22,
  },
  23: {
    message: "Employee with this employeeID does not exist",
    status: 400,
    code: 23,
  },
  24: {
    message: "Partner does not have access to this company's information",
    status: 400,
    code: 24,
  },
  25: {
    message:
      "This is only a Live Mode feature. Test Key does not have permission to perform the request.",
    status: 403,
    code: 25,
  },
  26: {
    message: "No shiftID in the query of request",
    status: 400,
    code: 26,
  },
  27: {
    message: "No 'new_employees' array found in the body of request",
    status: 400,
    code: 27,
  },
  28: {
    message: "Employee object(s) are missing the required fields",
    status: 400,
    code: 28,
  },
  29: {
    message: "Worker(s) with given email(s) already exist. Please try again.",
    status: 404,
    code: 29,
  },
  30: {
    message: "Employee is already onboarded/on leave/terminated.",
    status: 404,
    code: 30,
  },
  31: {
    message: "No Pending Employee Check exists with the given employeeCheckID.",
    status: 404,
    code: 31,
  },
  32: {
    message:
      "Employee Check for this employee and check date already exists. Error object will include an employeeID and check_date.",
    status: 400,
    code: 32,
  },
  33: {
    message:
      "This employee is working in a state for which a State Payroll Tax Number has not been submitted. This error only applies in Live Mode.",
    status: 400,
    code: 33,
  },
  34: {
    message: "The address provided is not suitable for taxation.",
    status: 400,
    code: 34,
  },
  35: {
    message: "No contractorID in the body/query of request.",
    status: 400,
    code: 35,
  },
  36: {
    message: "Object(s) does not have a bank account",
    status: 400,
    code: 36,
  },
  37: {
    message: "Cannot onboard employee: missing property",
    status: 400,
    code: 37,
  },
  38: {
    message: "Employee is already onboarded",
    status: 400,
    code: 38,
  },
  39: {
    message: "500 Internal Server Error",
    status: 500,
    code: 39,
  },
  40: {
    message: "CompanyID mismatch in body and query of request.",
    status: 400,
    code: 40,
  },
  41: {
    message: "Key is not authorized to perform this request.",
    status: 403,
    code: 41,
  },
  42: {
    message: "No contractorID or employeeID in the body/query of request.",
    status: 400,
    code: 42,
  },
  43: {
    message: "Employee Check with this check ID does not exist.",
    status: 404,
    code: 43,
  },
  44: {
    message: "Date is not a valid date.",
    status: 400,
    code: 44,
  },
  45: {
    message: "Date must not be in the past.",
    status: 400,
    code: 45,
  },
  46: {
    message: "Contractor Payment with this payment ID does not exist.",
    status: 404,
    code: 46,
  },
  47: {
    message: "Validation Error: ",
    status: 403,
    code: 47,
  },
  /**
   * TODO (Sahil, 04/04/24, ZF1-6089): Fix message copy and send comms, just in case
   */
  48: {
    message:
      "Invalid address. Please make sure address inputted is mail deliverable.",
    status: 400,
    code: 48,
  },
  49: {
    message: "Company must Be live",
    status: 403,
    code: 49,
  },
  50: {
    message: "Must provide a valid companyID.",
    status: 400,
    code: 50,
  },
  51: {
    message: "No company with this companyID exists.",
    status: 404,
    code: 51,
  },
  52: {
    message: "No document with this document ID exists.",
    status: 404,
    code: 52,
  },
  53: {
    message: "No document of this type within the given scope exists.",
    status: 404,
    code: 53,
  },
  54: {
    message:
      "No document with this jurisdiction and company ID have been submitted.",
    status: 404,
    code: 54,
  },
  55: {
    message: "Shifts with negative wages not currently supported.",
    status: 400,
    code: 55,
  },
  56: {
    message: "Routing number is invalid",
    status: 400,
    code: 56,
  },
  57: {
    message: "Employee or contractor has not finished onboarding.",
    status: 400,
    code: 57,
  },
  58: {
    message: "Work Location(s) not found",
    status: 400,
    code: 58,
  },
  59: {
    message: "Failed to update/upsert Work Location",
    status: 400,
    code: 59,
  },
  60: {
    message: "Failed to create Work Location",
    status: 400,
    code: 60,
  },
  61: {
    message: "Another employee in this company already has the same SSN",
    status: 409,
    code: 61,
  },
  62: {
    message: "Create employees payload contains duplicate SSNs",
    status: 400,
    code: 62,
  },
  63: {
    message: "SSN must be an optional property or of type string",
    status: 400,
    code: 63,
  },
  64: {
    message: "Request is not of the expected shape.",
    status: 400,
    code: 64,
  },
  65: {
    message: "Maximum number allowed checks exceeded.",
    status: 400,
    code: 65,
  },
  66: {
    message:
      "Only pending checks can be previewed. Received non pending check(s).",
    status: 400,
    code: 66,
  },
  67: {
    message: "No workLocationID in the body of request.",
    status: 400,
    code: 67,
  },
  68: {
    message:
      "Bank Account with this employeeID or contractorID does not exist.",
    status: 404,
    code: 68,
  },
  69: {
    message:
      "Another employee in this company already has the same external ID.",
    status: 409,
    code: 69,
  },
  70: {
    message:
      "Another contractor in this company already has the same external ID.",
    status: 409,
    code: 70,
  },
  71: {
    message: "No W-4 exists for this jurisdiction.",
    status: 400,
    code: 71,
  },
  72: {
    message: "Employee or Contractor ID is not formatted correctly",
    status: 400,
    code: 72,
  },
  73: {
    message: "Contractor with this contractorID does not exist",
    status: 400,
    code: 73,
  },
  74: {
    message: "Invalid check date",
    status: 400,
    code: 74,
  },
  75: {
    message: "Must be valid SSN format - eg. XXXYYZZZZ",
    status: 400,
    code: 75,
  },
  76: {
    message: "Another contractor in this company already has the same SSN",
    status: 409,
    code: 76,
  },
  77: {
    message: "Start cannot be after end date",
    status: 400,
    code: 77,
  },
  78: {
    message: "Email already exists.",
    status: 409,
    code: 78,
  },
  79: {
    message: "Migration failed",
    status: 400,
    code: 79,
  },
  80: {
    message: "Please provide a file to upload",
    status: 400,
    code: 80,
  },
  81: {
    message:
      "Contractor Payment for this contractor and pay date already exists. Error object will include a contractorID and pay_date.",
    status: 400,
    code: 80,
  },
  82: {
    message: "CompanyID was not found",
    status: 404,
    code: 82,
  },
  83: {
    message: "Failed to retrieve Partner Information by ID",
    status: 400,
    code: 83,
  },
  84: {
    message: "Email or password is incorrect",
    status: 401,
    code: 84,
  },
  85: {
    message: "Partner not found",
    status: 404,
    code: 85,
  },
  86: {
    message: "Either document ID or type, jurisdiction, and scope are required",
    status: 400,
    code: 86,
  },
  87: {
    message: "Employee has missing or invalid residency.",
    status: 422,
    code: 87,
  },
  88: {
    message: "Contractor is already onboarded",
    status: 400,
    code: 88,
  },
  89: {
    message:
      "The given date is on a weekend/bank holiday or the current time is after 2 PM two days before the given date. Please correct the date and resubmit.",
    status: 400,
    code: 89,
  },
  90: {
    message:
      "Only able to regenerate requirement for state scoped requirements",
    status: 400,
    code: 90,
  },
  91: {
    message: "Invalid tax parameter jurisdiction",
    status: 400,
    code: 91,
  },
  92: {
    message: "Invalid tax requirement ID",
    status: 400,
    code: 92,
  },
  93: {
    message: "Invalid tax parameter value",
    status: 400,
    code: 93,
  },
  94: {
    message:
      "Employee's default wage has not been set so please provide an appropriate wage in the shifts object(s).",
    status: 400,
    code: 94,
  },
  95: {
    message: "The requested feature is disabled.",
    status: 403,
    code: 95,
  },
  96: {
    message: "Value must be positive.",
    status: 400,
    code: 96,
  },
  97: {
    message: "Email must be valid",
    status: 400,
    code: 97,
  },
  98: {
    message: "This account does not have access to this endpoint",
    status: 403,
    code: 98,
  },
  99: {
    message: "This employee is not onboarded.",
    status: 400,
    code: 99,
  },
  100: {
    message: "This employee is on leave/terminated.",
    status: 400,
    code: 100,
  },
  101: {
    message: "Forbidden",
    status: 403,
    code: 101,
  },
  102: {
    message: "You must create between 1 and 100 checks",
    status: 400,
    code: 102,
  },
  103: {
    message: "Unauthorized",
    status: 401,
    code: 103,
  },
  104: {
    message: "Provided deduction template id(s) not found under companyId",
    status: 404,
    code: 104,
  },
  105: {
    message: "Parameter is expected to be of type objectid string",
    status: 400,
    code: 105,
  },
  106: {
    message: "Net pay negative - this check will fail.",
    status: 422,
    code: 106,
  },
  107: {
    message: "Bad Form Data",
    status: 422,
    code: 107,
  },
  108: {
    message: "Provided check is invalid",
    status: 400,
    code: 108,
  },
  109: {
    message: "Accrual policy with this policy code not found",
    status: 404,
    code: 109,
  },
  110: {
    message: "Historical shift would be modified by overtime calculation",
    status: 422,
    code: 110,
  },
  111: {
    message: "Value for earning type must be less than 0",
    status: 400,
    code: 111,
  },
  112: {
    message: "Value must be negative.",
    status: 400,
    code: 112,
  },
  113: {
    message: "Jurisdiction does not exist",
    status: 404,
    code: 113,
  },
  114: {
    message: "Company not setup for regular payroll",
    status: 400,
    code: 114,
  },
  115: {
    message: "Account not found",
    status: 404,
    code: 115,
  },
  116: {
    message: "Value must be a decimal.",
    status: 400,
    code: 116,
  },
  117: {
    message: "Please try again in a few seconds",
    status: 429,
    code: 117,
  },
  118: {
    message:
      "Shift has wage violations. Review and correct or run with shouldErrorOnWageViolations set to false.",
    status: 400,
    code: 118,
  },
  119: {
    message: "External ID must be a valid ID string.",
    status: 400,
    code: 119,
  },
  120: {
    message: "Provided employee ID is not a valid ID.",
    status: 400,
    code: 120,
  },
  121: {
    message:
      "This worker has failed their KYC check, and cannot be paid via direct deposit. Please select a different disbursement method.",
    status: 400,
    code: 121,
  },
  122: {
    message: "Provided company ID is not a valid ID.",
    status: 400,
    code: 122,
  },
  123: {
    message: "Provided customer account ID is not valid.",
    status: 400,
    code: 123,
  },
  124: {
    message: "No customer account with this customerAccountID exists.",
    status: 404,
    code: 124,
  },
  125: {
    message: "Invalid file type.",
    status: 400,
    code: 125,
  },
  126: {
    message: "Your company does not have access to interact with this route.",
    status: 403,
    code: 126,
  },
  127: {
    message:
      "Payment for this contractor and pay date already exists. Please choose another date or add to the existing payment object.",
    status: 400,
    code: 127,
  },
  128: {
    message: "No employer check with this employerCheckID exists.",
    status: 404,
    code: 128,
  },
  129: {
    message: "'shifts' array is empty or missing in the body of request.",
    status: 400,
    code: 129,
  },
  130: {
    message: "State agency not found",
    status: 404,
    code: 130,
  },
  131: {
    message: "Could not find the I9OnboardingInfo record for this worker.",
    status: 404,
    code: 131,
  },
  132: {
    message: "Could not send message to authorized representative.",
    status: 400,
    code: 132,
  },
  133: {
    message: "Could not send email to authorized representative.",
    status: 400,
    code: 133,
  },
  134: {
    message: "Could not find the I9OnboardingInfo record for this worker.",
    status: 404,
    code: 134,
  },
  135: {
    message: "Could not find the request entity in the database.",
    status: 404,
    code: 135,
  },
  136: {
    message:
      "Invalid date of birth: age must be between 14 years and 115 years",
    status: 400,
    code: 44,
  },
  137: {
    message: "Must be valid EIN format - eg. XXYYYYYYY",
    status: 400,
    code: 137,
  },
  138: {
    message:
      "Email not confirmed. Check your inbox for a confirmation email from hello@payrollcomms.com",
    status: 403,
    code: 138,
  },
  139: {
    message:
      "Forbidden product type. Please contact support to change your product type.",
    status: 403,
    code: 138,
  },
  140: {
    message: "Employee has already completed their I-9 flow.",
    status: 400,
    code: 140,
  },
  141: {
    message:
      "Employee hasn't completed the personal information section of their onboarding yet.",
    status: 400,
    code: 141,
  },
} as const;

export const ZealErrorAlias = {
  NO_PAYROLL_ACCESS: ZealErrors[0],
  AUTHORIZATION_ERROR: ZealErrors[1],
  NO_COMPANY_ID: ZealErrors[2],
  NO_COMPANY_ID_BODY: ZealErrors[3],
  COMPANY_ID_MISMATCH: ZealErrors[40],
  INVALID_COMPANY_ID: ZealErrors[4],
  MISSING_WHITE_LABEL_ID: ZealErrors[6],
  INVALID_TIME_FORMAT: ZealErrors[9],
  REPORTING_PERIOD_NOT_FOUND: ZealErrors[10],
  NO_SHIFTS_ARRAY_IN_BODY: ZealErrors[11],
  MISSING_REQUIRED_OR_INVALID: ZealErrors[13],
  OBJECT_DOES_NOT_EXIST: ZealErrors[17],
  END_DATE_BEFORE_START_DATE: ZealErrors[21],
  EMPLOYEE_NOT_FOUND: ZealErrors[23],
  LIVE_MODE_ONLY: ZealErrors[25],
  NO_SHIFT_ID: ZealErrors[26],
  NO_NEW_EMPLOYEES_ARRAY: ZealErrors[27],
  EMPLOYEE_MISSING_REQUIRED_FIELDS: ZealErrors[28],
  WORKER_EMAIL_ALREADY_EXISTS: ZealErrors[29],
  EMPLOYEE_WRONG_STATUS: ZealErrors[30],
  NO_PENDING_CHECK: ZealErrors[31],
  CHECK_ALREADY_EXISTS: ZealErrors[32],
  ADDRESS_UNSUITABLE: ZealErrors[34],
  EMPLOYEE_ALREADY_ONBOARDED: ZealErrors[38],
  INTERNAL_SERVER_ERROR: ZealErrors[39],
  KEY_NOT_AUTHORIZED: ZealErrors[41],
  EMPLOYEE_CHECK_NOT_FOUND: ZealErrors[43],
  INVALID_DATE: ZealErrors[44],
  DATE_IN_PAST: ZealErrors[45],
  CONTRACTOR_PAYMENT_NOT_FOUND: ZealErrors[46],
  VALIDATION_ERROR: ZealErrors[47],
  INVALID_ADDRESS: ZealErrors[48],
  COMPANY_NOT_FOUND: ZealErrors[51],
  INVALID_DOCUMENT_ID: ZealErrors[52],
  DOCUMENT_DOES_NOT_EXIST: ZealErrors[53],
  INVALID_DOCUMENT_JURISDICTION: ZealErrors[54],
  NEGATIVE_WAGE_SHIFT: ZealErrors[55],
  INVALID_ROUTING_NUMBER: ZealErrors[56],
  NOT_ONBOARDED: ZealErrors[57],
  WORK_LOCATION_NOT_FOUND: ZealErrors[58],
  FAIL_WORK_LOCATION_PATCH: ZealErrors[59],
  FAIL_WORK_LOCATION_CREATE: ZealErrors[60],
  NON_UNIQUE_SSN_EMPLOYEE: ZealErrors[61],
  DUPLICATE_SSN: ZealErrors[62],
  SSN_TYPE: ZealErrors[63],
  INVALID_REQUEST: ZealErrors[64],
  BANK_ACCOUNT_DONT_EXIST: ZealErrors[68],
  DUPLICATE_EXTERNAL_ID: ZealErrors[69],
  DUPLICATE_CONTRACTOR_EXTERNAL_ID: ZealErrors[70],
  W4_DONT_EXIST: ZealErrors[71],
  INVALID_EMPLOYEE_OR_CONTRACTOR_ID: ZealErrors[72],
  CONTRACTOR_NOT_FOUND: ZealErrors[73],
  INVALID_CHECK_DATE: ZealErrors[74],
  INVALID_SSN: ZealErrors[75],
  NON_UNIQUE_SSN_CONTRACTOR: ZealErrors[76],
  DUPLICATE_EMAIL: ZealErrors[78],
  MIGRATION_FAILURE: ZealErrors[79],
  MISSING_FILE: ZealErrors[80],
  DUPLICATE_CONTRACTOR_PAYMENT_DATE: ZealErrors[81],
  FAIL_GET_WHITE_LABEL_BY_ID: ZealErrors[83],
  FAIL_GET_WHITE_LABEL_INFO: ZealErrors[83],
  EMAIL_OR_PASSWORD_INCORRECT: ZealErrors[84],
  WHITE_LABEL_NOT_FOUND: ZealErrors[85],
  DOCUMENT_ID_OR_OTHERS_REQUIRED: ZealErrors[86],
  EMPLOYEE_MISSING_RESIDENCY: ZealErrors[87],
  CONTRACTOR_ALREADY_ONBOARDED: ZealErrors[88],
  DATE_WOULD_BE_ROLLED_FORWARD: ZealErrors[89],
  INVALID_TAX_PARAMETER_SCOPE: ZealErrors[90],
  INVALID_TAX_PARAMETER_JURISDICTION: ZealErrors[91],
  INVALID_TAX_REQUIREMENT_ID: ZealErrors[92],
  INVALID_TAX_PARAMETER_FORMAT: ZealErrors[93],
  WAGE_NEEDED: ZealErrors[94],
  FEATURE_DISABLED: ZealErrors[95],
  NON_POSITIVE_NUMBER: ZealErrors[96],
  INVALID_EMAIL: ZealErrors[97],
  NO_ACCESS_TO_ENDPOINT: ZealErrors[98],
  EMPLOYEE_NOT_ONBOARDED: ZealErrors[99],
  EMPLOYEE_NOT_LIVE: ZealErrors[100],
  FORBIDDEN: ZealErrors[101],
  CREATE_ONLY_100_CHECKS: ZealErrors[102],
  UNAUTHORIZED: ZealErrors[103],
  PROVIDED_DEDUCTION_TEMPLATE_IDS_NOT_FOUND: ZealErrors[104],
  EXPECTED_OBJECT_ID_STRING: ZealErrors[105],
  NEGATIVE_NET_PAY_PREVIEW_ERROR: ZealErrors[106],
  BAD_FORM_DATA: ZealErrors[107],
  NON_PENDING_CHECK: ZealErrors[108],
  ACCRUAL_POLICY_NOT_FOUND: ZealErrors[109],
  HISTORICAL_SHIFTS_OT_PREVIEW_ERROR: ZealErrors[110],
  POSITIVE_WAGE_SHIFT: ZealErrors[111],
  NON_NEGATIVE_NUMBER: ZealErrors[112],
  INVALID_JURISDICTION: ZealErrors[113],
  INVALID_PAYROLL_COMPANY: ZealErrors[114],
  ACCOUNT_NOT_FOUND: ZealErrors[115],
  DECIMAL_NOT_FOUND: ZealErrors[116],
  LOGIN_RATE_LIMIT: ZealErrors[117],
  WAGE_VIOLATIONS: ZealErrors[118],
  INVALID_EXTERNAL_ID: ZealErrors[119],
  EMPLOYEE_ID_NOT_OBJECTID: ZealErrors[120],
  FAILED_KYC_NO_DIRECT_DEPOSIT: ZealErrors[121],
  COMPANY_ID_NOT_OBJECTID: ZealErrors[122],
  CUSTOMER_ACCOUNT_INVALID: ZealErrors[123],
  CUSTOMER_ACCOUNT_NOT_FOUND: ZealErrors[124],
  INVALID_FILE_TYPE: ZealErrors[125],
  NO_ACCESS_TO_ROUTE: ZealErrors[126],
  PAYMENT_ALREADY_EXISTS: ZealErrors[127],
  EMPLOYER_CHECK_NOT_FOUND: ZealErrors[128],
  MISSING_SHIFTS: ZealErrors[129],
  STATE_AGENCY_NOT_FOUND: ZealErrors[130],
  ONBOARDING_INFO_RECORD_NOT_FOUND: ZealErrors[131],
  TWILIO_MESSAGE_ERROR: ZealErrors[132],
  SENDGRID_EMAIL_ERROR: ZealErrors[133],
  I9_ONBOARDING_INFO_NOT_FOUND: ZealErrors[134],
  ENTITY_NOT_FOUND: ZealErrors[135],
  INVALID_DOB: ZealErrors[136],
  INVALID_EIN: ZealErrors[137],
  EMAIL_NOT_VALIDATED: ZealErrors[138],
  PRODUCT_TYPE_FORBIDDEN: ZealErrors[139],
  EMPLOYEE_ALREADY_COMPLETED_I9: ZealErrors[140],
  EMPLOYEE_PERSONAL_INFO_INCOMPLETE: ZealErrors[141],
} as const;

export const ZealErrorMessageMap = Object.keys(ZealErrors).reduce(
  (a, c) => {
    const key = Number(c);
    const zKey = key as keyof typeof ZealErrors;
    const zealError = ZealErrors[zKey];

    a[zealError.message] = zealError;
    return a;
  },
  {} as Record<string, ZealError>
);
