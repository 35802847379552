import {
  ECompanyType,
  EPaySchedule,
  EProductType,
  schemes,
  updateUserOnboardingSettingsSchema,
  zSchema,
} from "@zeal/common";

export const getUserInfoResponseSchema = zSchema.object({
  account_number: zSchema.string(),
  appIntegrations: zSchema.object({
    quickbooks: zSchema.object({
      bankAccount: zSchema.object({ Name: zSchema.string() }),
      enabled: zSchema.boolean(),
      mapping: zSchema.object({
        doubletime: zSchema.object({ Name: zSchema.string() }),
        employerTaxes: zSchema.object({ Name: zSchema.string() }),
        flat: zSchema.object({ Name: zSchema.string() }),
        hourly: zSchema.object({ Name: zSchema.string() }),
        overtime: zSchema.object({ Name: zSchema.string() }),
        piecework: zSchema.null(),
        pto: zSchema.object({ Name: zSchema.string() }),
        reimbursement: zSchema.object({ Name: zSchema.string() }),
        tips: zSchema.object({ Name: zSchema.string() }),
        vacation: zSchema.object({ Name: zSchema.string() }),
      }),
      realmId: zSchema.string(),
    }),
  }),
  approval_checks_no: zSchema.number(),
  business_address: zSchema.string(),
  business_city: zSchema.string(),
  business_ein: zSchema.string(),
  business_name: zSchema.string(),
  business_phone: zSchema.string(),
  business_state: zSchema.string(),
  business_zip: zSchema.string(),
  check_created_webhook: zSchema.string(),
  check_processed_webhook: zSchema.string(),
  classification: zSchema.enum(["PRODUCTION", "TEST"]),
  company_id: schemes.uuid(),
  company_type: zSchema.nativeEnum(ECompanyType),
  product_type: zSchema.nativeEnum(EProductType),
  completed_paperwork_no: zSchema.number(),
  contractor_payment_webhook: zSchema.string(),
  contractor_webhook: zSchema.string(),
  default_pay_schedule: zSchema.string(),
  direct_deposit_form: zSchema.boolean(),
  electronic_signature_form: zSchema.boolean(),
  email: zSchema.string(),
  employee_check_webhook: zSchema.string(),
  employee_webhook: zSchema.string(),
  feature_flags: zSchema.record(zSchema.boolean()),
  first_name: zSchema.string(),
  has_logo: zSchema.boolean(),
  has_uploaded_check: zSchema.boolean(),
  home_setup_mode: zSchema.literal(false),
  isMailingAddressDifferent: zSchema.literal(false),
  job_title: zSchema.string(),
  last_name: zSchema.string(),
  legal_structure: zSchema.string(),
  live_key: zSchema.string(),
  mail_address: zSchema.string(),
  mail_city: zSchema.string(),
  mail_state: zSchema.string(),
  mail_zip: zSchema.string(),
  not_onboarded_no: zSchema.number(),
  onboarding_url: zSchema.string(),
  ownership_percentage: zSchema.number(),
  permissions: zSchema.object({
    canCreateChecks: zSchema.boolean(),
    canCreateWorker: zSchema.boolean(),
    canSubmitTaxInformation: zSchema.boolean(),
    onboardingWorkerProfileOptional: zSchema.boolean(),
    customPaperworkEnabled: zSchema.boolean(),
  }),
  profile_config: zSchema.object({ useTwoFactor: zSchema.literal(false) }),
  puzzl_terms_conditions: zSchema.boolean(),
  regular_payroll_firstDate: zSchema.string(),
  regular_payroll_firstPayPeriodID: zSchema.string(),
  regular_payroll_schedule: zSchema.nativeEnum(EPaySchedule),
  reporting_agent_form: zSchema.boolean(),
  reserve_enabled: zSchema.boolean(),
  routing_number: zSchema.string(),
  ssn: zSchema.string(),
  status: zSchema.string(),
  tax_information_authorization_form: zSchema.boolean(),
  test_key: zSchema.string(),
  user_type: zSchema.literal("sole_co"),
  webhook_secret: zSchema.string(),
  white_label_id: zSchema.string(),
  whiteLabelID: schemes.uuid(),
  worker_created_webhook: zSchema.string(),
  worker_onboarding_webhook: zSchema.string(),
  worker_i9_webhook: zSchema.string(),
  worker_setup_mode: zSchema.literal(false),
  worker_shift_webhook: zSchema.string(),
  dob: zSchema.string(),
  address: zSchema.string(),
  address_line2: zSchema.string(),
  city: zSchema.string(),
  state: zSchema.string(),
  zip: zSchema.string(),
  onboarding_settings: updateUserOnboardingSettingsSchema,
});

export type TGetUserInfoResponseSchema = zSchema.infer<
  typeof getUserInfoResponseSchema
>;
