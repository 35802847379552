import { EKYCStatus } from "../../kyc/EKYCStatus";

export interface IProfile {
  readonly email?: string;
  readonly dob?: string;
  readonly first_name?: string;
  readonly middle_initial?: string;
  readonly kyc_status?: EKYCStatus;
  readonly last_name?: string;
  readonly phone_number?: string;
  readonly ssn: string;
  readonly address?: string;
  readonly address_line2?: string;
  readonly city?: string;
  readonly state?: string;
  readonly zip?: string;
  readonly signature_request_id?: string;
  readonly working_state?: string;
  readonly isLoading?: boolean;
  readonly onboarded?: boolean;
  readonly bankaccount?: IBank;
  readonly hash?: string;
  readonly veriff_id?: string;
  readonly veriff_stage?: string;
}

export interface IParsedQuery {
  readonly id_scan?: boolean;
  readonly employee_acct?: boolean;
  readonly i9?: boolean;
  readonly profile?: boolean;
}

export interface IStages {
  readonly employee_acct: boolean;
  readonly i9: boolean;
  readonly id_scan: boolean;
  readonly payment_method_required: boolean;
  readonly paperwork: boolean;
  readonly profile_info: boolean;
  readonly health_insurance: boolean;
}

type WorkerTypes = "Worker" | "contractor" | "internationalContractor";

export interface IOnboardingTokenInfo {
  readonly companyID: string;
  readonly employeeID: string;
  readonly workerType: WorkerTypes;
  readonly testMode: boolean;
  readonly stages: {
    bank_info: boolean;
    contractor_acct: boolean;
    employee_acct: boolean;
    i9: boolean;
    id_scan: boolean;
    paperwork: boolean;
    payment_method_required: boolean;
    profile_info: boolean;
    health_insurance: boolean;
  };
  readonly idScanDocuments?: Record<EWorkerVerificationIDType, boolean>;
  readonly isKYC: boolean;
  readonly useI9Flow?: boolean;
  readonly isI9Only: boolean;
}

export interface IMetadata {
  readonly stage: string;
  readonly allowOnboardedEmployee?: boolean;
  readonly test?: boolean;
  readonly message?: string;
  readonly employeeID?: string;
  readonly workerType?: "Worker" | "contractor" | "internationalContractor";
  readonly stages?: IStages;
  readonly parsedQuery?: IParsedQuery;
  readonly hasError?: boolean;
  readonly error: boolean | Error;
  readonly loadError?: string;
  readonly isLoading?: boolean;
  readonly isOnboarding?: boolean;
  readonly companyID?: string;
  readonly expiration?: string;
  readonly userType: string;
}

export interface ICompany {
  readonly whiteLabelID: string | null;
  readonly company_sigId?: string;
  readonly business_name?: string;
  readonly companyID?: string;
  readonly logo_url: string | null;
  readonly isLoading?: boolean;
  readonly signURL: string;
}

export interface IAccount {
  readonly password?: string;
  readonly email?: string;
  readonly confirm_password?: string;
}

export interface IPhoto {
  readonly open?: boolean;
  readonly confirmOpen?: boolean;
  readonly sscard_loading?: boolean;
  readonly photoData?: string;
  readonly constraints: IVeriffConstraints;
}

export type TVideoDimensionConstraint =
  | number
  | { min?: number; max?: number; ideal?: number; exact?: number };

export interface IVeriffConstraints {
  audio: boolean;
  video: {
    height: TVideoDimensionConstraint;
    width: TVideoDimensionConstraint;
  };
}

export interface IVeriffData {
  readonly doc_type?: TWorkerVerificationFields;
  readonly veriff_id?: string;
  readonly verification_completed?: boolean;
  readonly confirmOpen?: boolean;
}

export interface IBank {
  readonly institution_name?: string;
  readonly type: string;
  readonly account_number: string;
  readonly routing_number: string;
}

export interface IEmployeeOnboardingState {
  readonly metadata: IMetadata;
  readonly company: ICompany;
  readonly account: IAccount;
  readonly accountExists: boolean;
  readonly profile: IProfile;
  readonly photo: IPhoto;
  readonly verification: IVeriffData;
  readonly bank: IBank;
}

export enum EFormTypes {
  w4 = "w4",
  i9 = "i9",
  bank = "bank",
}

export enum OnboardingStages {
  ACCOUNT_INFO = "account_info",
  PROFILE_INFO = "profile_info",
  ID_VERIFICATION = "id",
  FED_W9 = "fed_w9",
  BANK_INFO = "bank_info",
  FINISH_ONBOARDING = "finish_onboarding",
  INTERNATIONAL_CONTRACTOR_ONBOARDING = "international_contractor_onboarding",
  HEALTH_INSURANCE = "health_insurance",
}

export type FormTypes = `${EFormTypes}`;
export type FormJurisdiction = string;
export type FormKey = `form-${FormTypes}-${FormJurisdiction}`;

export type IStage = FormKey | OnboardingSteps;

export enum OnboardingSteps {
  Profile = "profile_info",
  Account = "create_account",
  Verification = "verification",
  Bank = "bank",
  Onboarded = "onboarded",
  HealthInsurance = "health_insurance",
}

export const StepToStage = {
  [OnboardingSteps.Profile]: OnboardingStages.PROFILE_INFO,
  [OnboardingSteps.Account]: OnboardingStages.ACCOUNT_INFO,
  [OnboardingSteps.Verification]: OnboardingStages.ID_VERIFICATION,
  [OnboardingSteps.Bank]: OnboardingStages.BANK_INFO,
  [OnboardingSteps.Onboarded]: OnboardingStages.FINISH_ONBOARDING,
  [OnboardingSteps.HealthInsurance]: OnboardingStages.HEALTH_INSURANCE,
};

export enum DeprecatedSteps {
  Paperwork = "paperwork",
}

export type OnboardingStepKey = FormKey | OnboardingSteps;

export interface IProfilePayload {
  readonly first_name: string;
  readonly middle_initial: string;
  readonly last_name: string;
  readonly phone_number: string;
  readonly ssn: string;
  readonly address: string;
  readonly address_line2: string;
  readonly city: string;
  readonly state: string;
  readonly zip: string;
  readonly dob: string;
}

export interface IStepProps {
  readonly nextStep: () => void;
  readonly prevStep: () => void;
  readonly gotoStep?: (formKey: string) => void;
}

export type StageConfig = {
  key: string;
  nextKey?: string;
  StageComponent: ({
    nextPage,
    prevPage,
    gotoPage,
  }: {
    nextPage?: () => unknown;
    prevPage?: () => unknown;
    gotoPage?: (pageKey: string) => unknown;
  }) => unknown;
};

export enum EWorkerVerificationIDType {
  I9_REQUIREMENTS = "i9_requirements",
  GOVERNMENT_PHOTO_ID = "government_photo_id",
  PASSPORT = "passport",
  DRIVERS_LICENSE = "drivers_license", // equivalent to GOVERNMENT_PHOTO_ID with type drivers_license
  SOCIAL_SECURITY_CARD = "social_security_card",
  SOCIAL_SECURITY_CARD_BACK = "social_security_card_back",
  OTHER = "other",
  OTHER_BACK = "other_back",
}

export enum EWorkerVerificationOtherFields {
  GOVERNMENT_PHOTO_ID_TYPE = "government_photo_id_type",
  OTHER_ID_TYPE = "other_id_type",
}

export type TWorkerVerificationFields =
  | EWorkerVerificationIDType
  | EWorkerVerificationOtherFields;

export enum EGovernmentPhotoIDType {
  EAD_CARD = "ead_card", // EAD (Employment Authorization Document) card
  RESIDENT_ID = "resident_id", // Resident ID
  MILITARY_ID = "military_id", // Military ID
  STATE_ID = "state_id", // State ID
  DRIVERS_LICENSE = "drivers_license", // Driver’s license
  PERMANENT_RESIDENT_CARD = "permanent_resident_card", // Permanent resident card
}

export enum EMultiDocumentStatusType {
  INACTIVE = "inactive",
  VERIFF_MODAL = "veriff_modal",
  INTERNAL_MODAL = "internal_modal",
  WAITING_FOR_CONTINUE = "waiting_for_continue",
  CLICKED_CONTINUE = "clicked_continue",
  FINISHED = "finished",
  FINISHED_EARLY = "finished_early",
}
