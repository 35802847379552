export enum SessionStorageKeys {
  SESSION_TOKEN = "zeal_sessionToken",
  PRIMARY_TOKEN = "zeal_primaryToken",
  SECONDARY_TOKEN = "zeal_secondaryToken",
}

export type ClearSessionStorageOptions = {
  keepPrimary: boolean;
  keepSecondary: boolean;
  keepSession: boolean;
};

export const clearSessionStorageKeys = (
  options: ClearSessionStorageOptions = {
    keepPrimary: false,
    keepSecondary: false,
    keepSession: false,
  }
) => {
  if (!options.keepPrimary) {
    sessionStorage.removeItem(SessionStorageKeys.PRIMARY_TOKEN);
  }
  if (!options.keepSecondary) {
    sessionStorage.removeItem(SessionStorageKeys.SECONDARY_TOKEN);
  }
  if (!options.keepSession) {
    sessionStorage.removeItem(SessionStorageKeys.SESSION_TOKEN);
  }
};
