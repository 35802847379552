import { ICustomerAccount, IFundingSource, zSchema } from "@zeal/common";

export const getCustomerAccountRequestSchema = zSchema.object({
  company_id: zSchema.string(),
  customer_account_id: zSchema.string(),
  partner_id: zSchema.string(),
});

export type TGetCustomerAccountRequestSchema = zSchema.infer<
  typeof getCustomerAccountRequestSchema
>;

export type TCustomerAccountWithFundingSource = ICustomerAccount & {
  funding_source: IFundingSource;
};
