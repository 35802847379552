import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  TResolveTaxableLocationRequestSchema,
  TResolveTaxableLocationResponseSchema,
} from "./resolveTaxableLocationSchema";

export const resolveTaxableLocation = (axios: IAxiosInstance) => {
  return async (body: TResolveTaxableLocationRequestSchema) => {
    const response =
      await axios.noRetry.post<TResolveTaxableLocationResponseSchema>(
        `/api/taxable-locations/resolve`,
        body
      );

    return response.data;
  };
};
