import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const verifyRoutingNumber = (axios: IAxiosInstance) => {
  return async ({
    routing_number,
  }: ISchema["#/definitions/IVerifyRoutingNumberQueryParams"]) => {
    const response = await axios.noRetry.get(`/api/odo/bank/verify`, {
      params: { routing_number },
    });
    const data = response.data;

    if (data.success) {
      return data;
    }

    throw new Error("Something went wrong");
  };
};
