import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const updateShift = (axios: IAxiosInstance) => {
  return async ({
    old_shift,
    new_shift,
  }: ISchema["#/definitions/IUpdateShiftRequestBody"]) => {
    const response = await axios.noRetry.post(`api/updateShift`, {
      old_shift,
      new_shift,
    });

    if (!response?.data?.error) {
      return response;
    }

    throw new Error("Something went wrong");
  };
};
