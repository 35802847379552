export const EPaySchedule = {
  daily: "daily",
  weekly: "weekly",
  biweekly: "biweekly",
  semimonthly: "semimonthly",
  monthly: "monthly",
  quarterly: "quarterly",
} as const;

export type EPaySchedule = (typeof EPaySchedule)[keyof typeof EPaySchedule];

export const PAY_SCHEDULES = Object.values(
  EPaySchedule
) as ReadonlyArray<EPaySchedule>;
