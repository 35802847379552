import { EDeductionType } from "./EDeductionType";
import { ReadonlySet } from "../data-structures/ReadonlySet";

const NON_UNIQUE_DEDUCTION_TYPES = new ReadonlySet([
  EDeductionType.GARNISHMENT,
  EDeductionType.SECTION_125,
]);

export const UNIQUE_DEDUCTION_TYPES = new ReadonlySet(
  Object.values(EDeductionType).filter(
    (type) => !NON_UNIQUE_DEDUCTION_TYPES.has(type as any)
  )
);
