import { z } from "zod";
import { Jurisdiction } from "../jurisdictions";
import { EKYCStatus } from "../kyc";
import { ELaForgeWorkerEnum } from "../workers-shared";

const getQuickviewWorkerInfoResponseData = z.object({
  user_type: z.literal(ELaForgeWorkerEnum.Employee),
  default_pay_schedule: z.string(),
  autopilot_on: z.boolean(),
  complete_paperwork: z.boolean(),
  payroll_results: z.array(z.any()),
  pto: z.array(z.any()),
  vac: z.array(z.any()),
  onboarded: z.boolean(),
  employment_status: z.string(),
  is_943: z.boolean(),
  is_scheduleH: z.boolean(),
  onboard_docs_faxed: z.boolean(),
  is_regular: z.boolean(),
  is_salary: z.boolean(),
  _id: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  start_date: z.string(),
  term_date: z.string().optional(),
  email: z.string(),
  title: z.string(),
  address: z.string(),
  address_line2: z.string(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  phone_number: z.string(),
  ssn: z.string(),
  salary: z.number(),
  salary_firstDate: z.string(),
  default_wage: z.number(),
  default_ot_wage: z.number(),
  default_dt_wage: z.number(),
  workLocationID: z.string(),
  working_state: z.nativeEnum(Jurisdiction),
  companyID: z.string(),
  taxengine_workerID: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  __v: z.number(),
  dob: z.string(),
  middle_initial: z.string(),
  bankaccount: z.object({
    _id: z.string(),
    id: z.string(),
    __v: z.number(),
    account_number: z.string(),
    companyID: z.string(),
    createdAt: z.string(),
    institution_name: z.string(),
    routing_number: z.string(),
    type: z.union([z.literal("checking"), z.literal("savings")]),
    updatedAt: z.string(),
  }),
  kyc_status: z.nativeEnum(EKYCStatus).optional(),
});

export type TGetQuickviewWorkerInfoResponseData = z.infer<
  typeof getQuickviewWorkerInfoResponseData
>;
